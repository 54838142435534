<template lang="pug">
  tr(:key='selectionVoucher.id')
    td {{ selectionVoucher.id }}
    td {{ selectionVoucher.Name }}
    td {{ selectionVoucher.ItemId }}
    td
      p(v-if="item") {{ item.SystemItemId }}
    td {{ selectionVoucher.IsActive ? 'Aktiv' : 'Inaktiv' }}
    td
      router-link.button.button-primary.button-tdays(:to="{ name: 'SelectionVoucherDetails', params: { selectionVoucherId: selectionVoucher.id }, }")
        | zum Auswahlgutschein
</template>

<script>
import {Items} from "@/graphql/Items.ts";

export default {
  name: "SelectionVoucherTableRow",
  props: {
    selectionVoucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: undefined,
    }
  },
  async mounted() {
    try {
      await this.loadItem();
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async loadItem() {
      try {
        const item = await new Promise((resolve) => {
          this.$apollo.query({
            query: Items.Queries.Item,
            variables: {
              id: this.selectionVoucher.ItemId,
            }
          })
              .then(({data}) => {
                if (!data || !data.item) {
                  resolve(undefined);
                }
                resolve(data.item);
              })
              .catch((e) => {
                console.log(e);
                resolve(undefined);
              });
        });
        if (!item) {
          return;
        }
        this.item = item;
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>
