import gql from "graphql-tag";

export const CategoryHosts = {
    Mutations: {
        CreateCategoryHost: gql`
        mutation createCategoryHost($hostId: ID, $categoryId: ID) {
            createCategoryHost(hostId: $hostId, categoryId: $categoryId) {
                IsSuccess
                Message
            }
        }`,
        DeleteCategoryHost: gql`
        mutation deleteCategoryHost($hostId: ID, $categoryId: ID) {
            deleteCategoryHost(hostId: $hostId, categoryId: $categoryId) {
                IsSuccess
                Message
            }
        }`
    }
}
