<template lang="pug">
  .create-selection-voucher-json-item.p-4
    .container-fluid
      JsonItemForm(title="Neuer Artikel" @save="createSelectionVoucherJsonItem" @close="$emit('close')")
</template>

<script>
import EventBus from "@/views/event-bus";
import {SelectionVouchers} from "@/graphql/SelectionVouchers.ts";
import JsonItemForm from "@/views/modals/selectionVouchers/partials/JsonItemForm";

export default {
  name: "CreateSelectionVoucherJsonItem",
  components: {JsonItemForm},
  props: {
    selectionVoucherId: {
      type: String,
      required: true,
    },
  },
  methods: {
    createSelectionVoucherJsonItem(item) {
      try {
        if(!item) return;
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.mutate({
          mutation: SelectionVouchers.Mutations.CreateSelectionVoucherJsonItem,
          variables: {
            selectionVoucherJsonItem: item,
            selectionVoucherId: this.selectionVoucherId,
          }
        })
        .then(({ data }) => {
          EventBus.$emit("changeLoadingState", false);
          if (!data || !data.createSelectionVoucherJsonItem) {
            return this.$alert("Es konnte kein Artikel erstellt werden.");
          }
          if (data.createSelectionVoucherJsonItem.Message) {
            data.createSelectionVoucherJsonItem.IsSuccess ? this.$swal(data.createSelectionVoucherJsonItem.Message) : this.$alert(data.createSelectionVoucherJsonItem.Message);
          }
          if (data.createSelectionVoucherJsonItem.IsSuccess) {
            EventBus.$emit("updateSelectionVoucher");
            this.$emit("close");
          }
        })
        .catch((e) => {
          EventBus.$emit("changeLoadingState", false);
          console.log(e);
          this.$alert(e.message);
        })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  }
};
</script>
