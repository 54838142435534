<template lang="pug">
.marketing-promo-contest-details
  .container-fluid
    .row
      .col-12
        h4 Gewinnspieldetails

      .col-12
        ul.nav-tabs.nav
          li.nav-item
            a.nav-link.rounded-0.active(data-toggle="tab" href="#marketingPromoContestDetails" role="tab" aria-selected="true") Gewinnspiel
          li.nav-item
            a.nav-link.rounded-0(data-toggle="tab" href="#marketingPromoContestPrizes" role="tab" aria-selected="false") Preise
          li.nav-item
            a.nav-link.rounded-0(data-toggle="tab" href="#marketingPromoContestStats" role="tab" aria-selected="false") Statistik
          li.nav-item
            a.nav-link.rounded-0(data-toggle="tab" href="#marketingPromoContestWinners" role="tab" aria-selected="false" @click="getContestWinners") Gewinner
        .tab-content
          #marketingPromoContestDetails.tab-pane.fade.show.active
            .container-fluid.mt-2
              .row
                .col-12.mt-2
                  label.form-label Name
                  input.form-control(type="text" :value="marketingPromoContest.Name" disabled)

                .col-12.mt-2
                  label.form-label Beschreibung
                  textarea-autosize.form-control(v-model="marketingPromoContest.Description")

                .col-6.mt-2
                  .form-group
                    label.form-label Registration von
                    input.form-control(type="datetime-local" :value="marketingPromoContest.RegistrationFrom | formatInputDateTime" @change="marketingPromoContest.RegistrationFrom = $event.target.value;")
                .col-6.mt-2
                  .form-group
                    label.form-label Registration bis
                    input.form-control(type="datetime-local" :value="marketingPromoContest.RegistrationTill | formatInputDateTime" @change="marketingPromoContest.RegistrationTill = $event.target.value;")

                .col-6.mt-2
                  .form-group
                    label.form-label Preise gültig von*
                    input.form-control(type="datetime-local" :value="marketingPromoContest.PrizeEnabledFrom | formatInputDateTime" @change="marketingPromoContest.PrizeEnabledFrom = $event.target.value;")
                .col-6.mt-2
                  .form-group
                    label.form-label Preise gültig bis*
                    input.form-control(type="datetime-local" :value="marketingPromoContest.PrizeEnabledTill | formatInputDateTime" @change="marketingPromoContest.PrizeEnabledTill = $event.target.value;")

                .col.mt-2
                  .display-block
                    button.button.button-primary.button-tdays(@click="openSendMarketingPromoContestParticipantSignupEmailModal")
                      | Anmeldung E-Mail senden
                    button.button.button-primary.button-tdays(style="margin-left: 0.75rem;" @click="openSendMarketingPromoContestFriendSharingEmailModal")
                          | Friend-Sharing E-Mail senden

                .col-6.mt-2
                  .float-right
                    button.button.button-primary.button-tdays(@click="updateMarketingPromoContest")
                      | speichern

          #marketingPromoContestPrizes.tab-pane.fade
            .container-fluid.mt-2
              .row
                .col-12
                  ul.nav-tabs.nav
                    li.nav-item
                      a.nav-link.rounded-0.active(data-toggle="tab" href="#marketingPromoContestItemPrizes" role="tab" aria-selected="true") Artikelpreise
                    li.nav-item
                      a.nav-link.rounded-0(data-toggle="tab" href="#marketingPromoContestDiscountVoucherPrizes" role="tab" aria-selected="false") Gutscheinpreise
                  .tab-content
                    #marketingPromoContestItemPrizes.tab-pane.fade.show.active
                      .container-fluid
                        .row
                          .col-12
                            table.table.w-100
                              thead
                                tr
                                  th Id
                                  th Headline
                                  th Preis wird gezogen am
                                  th Preis gezogen am
                                  th Artikel-Id
                                  th #
                                  th #
                              tbody
                                tr(v-for='marketingPromoContestPrize in marketingPromoContestPrizes.filter(p => !p.IsDiscountOnlyPrize)' :key='marketingPromoContestPrize.id')
                                  td {{ marketingPromoContestPrize.id }}
                                  td {{ marketingPromoContestPrize.HtmlHeadline }}
                                  td {{ marketingPromoContestPrize.DrawPrizeAt | formatDateTime }}
                                  td {{ marketingPromoContestPrize.PrizeDrawnAt | formatDateTime }}
                                  td {{ marketingPromoContestPrize.ItemId }}
                                  td
                                    button.button.button-primary.button-tdays(@click="openSendMarketingPromoContestPrizeWinnerEmailModal(marketingPromoContestPrize.id)")
                                      | E-Mail senden
                                  td
                                    button.button.button-primary.button-tdays(@click="openUpdateMarketingPromoContestPrizeModal(marketingPromoContestPrize.id)")
                                      | zum Preis
                          .col-12
                            .float-right
                              button.button.button-primary.button-tdays(@click="openCreateMarketingPromoContestPrizeModal(false)")
                                | Artikelpreis erstellen

                    #marketingPromoContestDiscountVoucherPrizes.tab-pane.fade
                      .container-fluid
                        .row
                          .col-12
                            table.table.w-100
                              thead
                                tr
                                  th Id
                                  th Code
                                  th Wert
                                  th Preis wird gezogen am
                                  th Preis gezogen am
                                  th #
                                  th #
                              tbody
                                tr(v-for='marketingPromoContestPrize in marketingPromoContestPrizes.filter(p => p.IsDiscountOnlyPrize)' :key='marketingPromoContestPrize.id')
                                  td {{ marketingPromoContestPrize.id }}
                                  td {{ marketingPromoContestPrize.Code }}
                                  td {{ marketingPromoContestPrize.Value | formatCurrency }}
                                  td {{ marketingPromoContestPrize.DrawPrizeAt | formatDateTime }}
                                  td {{ marketingPromoContestPrize.PrizeDrawnAt | formatDateTime }}
                                  td
                                    button.button.button-primary.button-tdays(@click="openSendMarketingPromoContestPrizeWinnerEmailModal(marketingPromoContestPrize.id)")
                                      | E-Mail senden
                                  td
                                    button.button.button-primary.button-tpics(@click="deleteMarketingPromoContestPrize(marketingPromoContestPrize)")
                                      | löschen
                          .col-12
                            .float-right
                              button.button.button-primary.button-tdays(@click="openCreateMarketingPromoContestPrizeModal(true)")
                                | Gutscheinpreis erstellen

          #marketingPromoContestStats.tab-pane.fade
            .container-fluid
              .row
                .col-12
                  MarketingPromoContestCharts(:key="this.marketingPromoContest.Name" :marketing-promo-contest-id="this.marketingPromoContestId" :marketing-promo-contest-name="this.marketingPromoContest.Name" )
          #marketingPromoContestWinners.tab-pane.fade
            .container-fluid
              .d-flex.align-items-center(style="gap:1rem")
                label.d-flex.align-items-center.pt-3.pb-1
                  span Tabelle Zeigt:
                  select.ml-1(v-model="filterType")
                    option(value="both") Alle
                    option(value="discountOnly") Nur Wertgutscheine
                    option(value="productOnly") Nur Artikelpreise
                label.d-flex.align-items-center.pt-3.pb-1
                  b-form-checkbox(v-model="hideOrderedPrices" switch) Bereits Bestellte Artikelpreise ausblenden
              table.table.w-100
                thead
                  tr
                    th
                      input(type="checkbox" @click="toggleAllWinners")
                    th E-Mail Addresse
                    th Ticket
                    th Preis
                    th Bestell-Nr. (Nur Artikelpreise)
                    th gezogen am
                    th
                      button.button.button-primary.button-tdays(@click="notifySelectedWinners") ausgewählte benachrichtigen
                tbody
                  tr(v-for="prize in marketingPromoContestWinners" v-if="(!hideOrderedPrices || !prize.InternalOrderId) && (filterType === 'both' || (filterType === 'discountOnly' && prize.IsDiscountOnlyPrize) || (filterType === 'productOnly' && !prize.IsDiscountOnlyPrize))")
                    td
                      input(type="checkbox" v-model="prize.isSelected")
                    td {{prize.MarketingPromoParticipant.Email}}
                    td {{prize.MarketingPromoParticipant.MarketingPromoContestParticipants[0].Ticket}}
                    td {{prize.HtmlHeadline}}
                    td
                      .d-flex.align-items-center(v-if="prize.Item")
                        input(type="text" v-model="internalOrderIds[prize.id]" @keyup.enter="updateInternalOrderId(prize)")
                        button(@click="updateInternalOrderId(prize)")
                          IconSave
                    td {{formatDate(prize.PrizeDrawnAt)}}
                    td
                      .d-flex.align-items-center(style="gap:1rem")
                        button.button.button-primary.button-tdays(@click="sendWinnerMailTo(prize)") benachrichtigen
                        a(:href="`https://pm.touridat.com/#/hosts/${prize.Item.Host.SystemHostId}/products/${prize.Item.SystemItemId}`" target="_blank" v-if="prize.Item && prize.Item.Host && prize.Item.Host.SystemHostId && prize.Item.SystemItemId") zum Artikel
                        a(:href="`https://pm.touridat.com/#/service/orders/overview/${prize.InternalOrderId}`" target="_blank" v-if="prize.InternalOrderId") zur Bestellung
</template>

<script>
import EventBus from "@/views/event-bus";
import {MarketingPromoContests} from "@/graphql/MarketingPromoContests.ts";
import CreateMarketingPromoContestPrizeModal
  from "@/views/modals/marketingPromoContestPrizes/CreateMarketingPromoContestPrizeModal";
import {MarketingPromoContestPrizes} from "@/graphql/MarketingPromoContestPrizes.ts";
import UpdateMarketingPromoContestPrizeModal
  from "@/views/modals/marketingPromoContestPrizes/UpdateMarketingPromoContestPrizeModal";
import SendMarketingPromoContestPrizeWinnerEmailModal
  from "@/views/modals/marketingPromoContestPrizes/SendMarketingPromoContestPrizeWinnerEmailModal";
import SendMarketingPromoContestParticipantSignupEmailModal
  from "@/views/modals/marketingPromoContestPrizes/SendMarketingPromoContestParticipantSignupEmailModal";
import SendMarketingPromoContestFriendSharingEmailModal
  from "@/views/modals/marketingPromoContestPrizes/SendMarketingPromoContestFriendSharingEmailModal";
import MarketingPromoContestCharts from "@/views/marketingPromoContests/MarketingPromoContestCharts";
import moment from "moment";
import IconSave from "@/views/partials/icons/IconSave.vue";

export default {
  name: "MarketingPromoContestDetails",
  props: {
    marketingPromoContestId: {
      type: String,
      required: true,
    },
  },
  setup(){
    const formatDate = (date) => {
      return moment(date).format("DD.MM.YYYY")
    }

    return {formatDate};
  },
  components: {
    IconSave,
    MarketingPromoContestCharts
  },
  data() {
    return {
      marketingPromoContest: {},
      marketingPromoContestPrizes: [],
      marketingPromoContestWinners:[],
      internalOrderIds:{},
      hideOrderedPrices:false,
      filterType:"both"
    };
  },
  mounted() {
    try {
      EventBus.$on(
          "updateMarketingPromoContestPrizesList",
          function () {
            this.loadMarketingPromoContestPrizes();
          }.bind(this)
      );
      this.loadMarketingPromoContest();
      this.loadMarketingPromoContestPrizes();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async updateInternalOrderId(prize){
      try {
        const internalOrderId = this.internalOrderIds?.[prize.id] || prize.InternalOrderId;
        EventBus.$emit("changeLoadingState", true);
        const updatedPrize = await this.$apollo.mutate({
          mutation: MarketingPromoContestPrizes.Mutations.UpdateMarketingPromoContestPrize,
          variables: {
            marketingPromoContestPrize: {
              id:prize.id,
              InternalOrderId: internalOrderId?.trim()?.length ? internalOrderId : null
            },
          }
        }).then((response) => response.data.updateMarketingPromoContestPrize);
        prize.InternalOrderId = updatedPrize.InternalOrderId;
        await this.$alert("Der Preis wurde erfolgreich gespeichert.");
      } catch(e){
        await this.$alert("Beim speichern des Preises ist ein Fehler aufgetreten.");
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    },
    toggleAllWinners(e){
      const isChecked = e?.target?.checked || false;
      this.marketingPromoContestWinners.forEach((winner) => {
        winner.isSelected = isChecked;
      })
    },
    async getContestWinners(){
      if(!this.marketingPromoContestId) return;
      try {
        EventBus.$emit("changeLoadingState", true);
        const prizes = await this.$apollo.query({
          query:MarketingPromoContestPrizes.Queries.MarketingPromoContestPrizes,
          variables:{
            marketingPromoContestId:this.marketingPromoContestId
          }
        }).then((response) => response?.data?.marketingPromoContestPrizes || [])
        this.marketingPromoContestWinners = prizes.filter((prize) => !!prize.PrizeDrawnAt).sort((a,b) => {
          const timeA = moment(a.PrizeDrawnAt).toDate();
          const timeB = moment(b.PrizeDrawnAt).toDate();

          return timeB - timeA;
        }).map((prize) => {
          return {
            ...prize,
            isSelected:false
          }
        });
      } catch (e) {
        console.error(e);
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    },
    loadMarketingPromoContest() {
      try {
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.query({
          query: MarketingPromoContests.Queries.MarketingPromoContest,
          variables: {
            id: this.marketingPromoContestId,
          }
        })
            .then(({data}) => {
              EventBus.$emit("changeLoadingState", false);
              if (!data || !data.marketingPromoContest) {
                return this.$alert("Es konnte kein Gewinnspiel geladen werden.");
              }
              this.marketingPromoContest = data.marketingPromoContest;
            })
            .catch((e) => {
              EventBus.$emit("changeLoadingState", false);
              console.log(e);
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadMarketingPromoContestPrizes() {
      try {
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.query({
          query: MarketingPromoContestPrizes.Queries.MarketingPromoContestPrizes,
          fetchPolicy: "no-cache",
          variables: {
            marketingPromoContestId: this.marketingPromoContestId,
          }
        })
            .then(({data}) => {
              EventBus.$emit("changeLoadingState", false);
              if (!data || !data.marketingPromoContestPrizes) {
                return this.$alert("Es konnte keine Preise geladen werden.");
              }
              this.marketingPromoContestPrizes = data.marketingPromoContestPrizes;
              this.marketingPromoContestPrizes.forEach((prize) => {
                if(!prize?.InternalOrderId) return;
                this.internalOrderIds[prize.id] = prize.InternalOrderId;
              })
            })
            .catch((e) => {
              EventBus.$emit("changeLoadingState", false);
              console.log(e);
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    updateMarketingPromoContest() {
      try {
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.mutate({
          mutation: MarketingPromoContests.Mutations.UpdateMarketingPromoContest,
          variables: {
            marketingPromoContest: this.marketingPromoContest,
          }
        })
            .then(({data}) => {
              EventBus.$emit("changeLoadingState", false);
              if (!data || !data.updateMarketingPromoContest) {
                return this.$alert("Das Gewinnspiel konnte nicht aktualisiert werden.");
              }
              if (data.updateMarketingPromoContest.Message) {
                this.$alert(data.updateMarketingPromoContest.Message);
              }
            })
            .catch((e) => {
              EventBus.$emit("changeLoadingState", false);
              console.log(e);
              this.$alert(e.message);
            })
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        console.log(e);
        this.$alert(e.message);
      }
    },
    openCreateMarketingPromoContestPrizeModal(isDiscountOnlyPrize) {
      try {
        this.$modal.show(
            CreateMarketingPromoContestPrizeModal,
            {
              isDiscountOnlyPrize: isDiscountOnlyPrize,
              marketingPromoContestId: this.marketingPromoContestId,
            },
            {
              height: "auto",
            },
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openSendMarketingPromoContestPrizeWinnerEmailModal(marketingPromoContestPrizeId) {
      try {
        if (!marketingPromoContestPrizeId) {
          return this.$alert("Es wurde keine Preis-Id übergeben.");
        }
        this.$modal.show(
            SendMarketingPromoContestPrizeWinnerEmailModal,
            {
              marketingPromoContestPrizeId: marketingPromoContestPrizeId,
            },
            {
              height: "auto",
            },
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openUpdateMarketingPromoContestPrizeModal(marketingPromoContestPrizeId) {
      try {
        if (!marketingPromoContestPrizeId) {
          return this.$alert("Es wurde keine Preis-Id übergeben.");
        }
        this.$modal.show(
            UpdateMarketingPromoContestPrizeModal,
            {
              marketingPromoContestPrizeId: marketingPromoContestPrizeId,
            },
            {
              height: "auto",
            }
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    deleteMarketingPromoContestPrize(marketingPromoContestPrize) {
      try {
        if (!marketingPromoContestPrize) {
          return this.$alert("Es wurde keine Preis-Id übergeben.");
        }
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.mutate({
          mutation: MarketingPromoContestPrizes.Mutations.DeleteMarketingPromoContestPrize,
          variables: {
            marketingPromoContestPrizeId: marketingPromoContestPrize.id,
          }
        })
            .then(({data}) => {
              if (!data || !data.deleteMarketingPromoContestPrize) {
                return this.$alert("Der Preis konnte nicht gelöscht werden.");
              }
              if (data.deleteMarketingPromoContestPrize.Message) {
                this.$alert(data.deleteMarketingPromoContestPrize.Message);
              }
              if (data.deleteMarketingPromoContestPrize.IsSuccess) {
                this.marketingPromoContestPrizes.splice(this.marketingPromoContestPrizes.indexOf(marketingPromoContestPrize), 1);
              }
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openSendMarketingPromoContestParticipantSignupEmailModal() {
      try {
        this.$modal.show(
            SendMarketingPromoContestParticipantSignupEmailModal,
            {},
            {
              height: "auto",
            },
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openSendMarketingPromoContestFriendSharingEmailModal() {
      try {
        this.$modal.show(
            SendMarketingPromoContestFriendSharingEmailModal,
            {},
            {
              height: "auto",
            },
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async sendWinnerMailTo(winnerPrize,alert = true){
      const email = winnerPrize?.MarketingPromoParticipant?.Email;
      const prizeId = winnerPrize?.id;
      if(!email || !prizeId) return;
      try {
        return await this.$apollo.mutate({
          mutation:MarketingPromoContestPrizes.Mutations.SendMarketingPromoContestPrizeWinnerEmail,
          variables:{
            marketingPromoContestPrizeId:prizeId,
            email
          }
        }).then((response) => {
          const payload = response?.data?.sendMarketingPromoContestPrizeWinnerEmail;
          if(!payload?.Message?.length) return;
          if(alert){
            return this.$alert(payload.Message)
          }
          return payload.Message;
        })
      } catch (e) {
        console.error(e);
      }
    },
    async notifySelectedWinners(){
      const selectedWinners = this.marketingPromoContestWinners.filter((winner) => winner.isSelected);
      await Promise.allSettled(selectedWinners.map((winner) => {
        return this.sendWinnerMailTo(winner,false);
      })).then(() => {
        this.$alert("Alle E-Mails wurden erfolgreich verschickt.")
      })
    }
  }
};
</script>

<style scoped>

</style>
