<template lang="pug">
.hosts-list
  .container-fluid
    .row
      .col-12
        h4 Gastgeber
      .col-12
        input.form-control(type="text" v-model="filter" placeholder="Moloss-Id oder Blaues Programm-Id..." v-on:keyup.enter="filterHosts")
      .col-12
        table.table.w-100
          thead
            tr
              th Id
              th Name
              th #
          tbody
            tr(v-for='host in hosts' :key='host.id')
              td {{ host.SystemHostId }}
              td {{ host.Name }}
              td
                router-link.button.button-primary.button-tdays(:to="{ name: 'HostDetails', params: { hostId: host.id }, }")
                  | zum Gastgeber
      .col-4.offset-4
        .text-center
          a.h5(:class="{ 'disabled': hosts.length == hostsCount }" @click="loadMoreHosts")
            | mehr laden

</template>

<script>
import {Hosts} from "@/graphql/Hosts.ts";

export default {
  name: "HostsList",
  data() {
    return {
      hosts: [],
      hostsCount: 0,

      filter: "",
      offset: 0,
      limit: 15,
    }
  },
  mounted() {
    this.loadHostsCount();
    this.loadHosts();
  },
  methods: {
    loadHostsCount() {
      try {
        this.$apollo.query({
          query: Hosts.Queries.HostsCount,
          variables: {
            filter: this.filter,
          }
        })
            .then(({data}) => {
              if (!data || !data.hostsCount) {
                return;
              }
              this.hostsCount = data.hostsCount;
            })
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    loadHosts() {
      try {
        this.$apollo.query({
          query: Hosts.Queries.Hosts,
          variables: {
            filter: this.filter,
            offset: this.offset,
            limit: this.limit,
          }
        })
            .then(({data}) => {
              if (!data || !data.hosts) {
                return;
              }
              this.hosts = this.hosts.concat(data.hosts);
            })
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    filterHosts() {
      try {
        this.offset = 0;
        this.hosts = [];
        this.loadHostsCount();
        this.loadHosts();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadMoreHosts() {
      try {
        if (this.hosts.length == this.hostsCount) {
          return;
        }
        this.offset += this.limit;
        this.loadHosts();
      } catch (e) {
        console.log(e);
        return undefined;
      }
    }
  },
}
</script>
