<template lang="pug">
#app
  td-loading-overlay(v-if="loading")
  Header(v-if="$session.exists()")
  .container-fluid
    RouterView.p-3(:key="$route.path")
</template>

<script>
import Header from "@/views/components/header/Header";
import EventBus from "@/views/event-bus";
import TdLoadingOverlay from "@/views/partials/td-loading-overlay.vue";

export default {
  name: 'App',
  components: {
    Header,
    TdLoadingOverlay
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    EventBus.$on(
        "changeLoadingState",
        function (loading) {
          this.loading = loading;
        }.bind(this)
    );
  }
}
</script>

<style lang="scss">
@import "assets/styles/base";
</style>
