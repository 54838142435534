<template lang="pug">
header.sticky-top
  #globalhead
    nav.navbar.navbar-expand-lg.navbar-dark.px-3
      router-link.navbar-brand.ml-3(:to="{ name: 'Home' }")
        img(src='https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touridat/branding/white/touridat-branding-white-50x50.png' width="25" height="25" alt="tD")
      button.navbar-toggler(type="button" data-toggle="collapse" data-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation")
        span.navbar-toggler-icon
      #mainNav.collapse.navbar-collapse
        ul.navbar-nav
          li.nav-item.active
            router-link.nav-link(:to="{ name: 'HostsList' }") Gastgeber
          li.nav-item
            router-link.nav-link(:to="{ name: 'CategoriesList' }") Kategorien
          li.nav-item
            router-link.nav-link(:to="{ name: 'SelectionVouchersList' }") Auswahlgutscheine
          li.nav-item
            router-link.nav-link(:to="{ name: 'MarketingPromoContestsList' }") Gewinnspiele
          li.nav-item.dropdown
            a#vouchers.nav-link.dropdown-toggle(href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
              | Gutscheine
            .dropdown-menu(aria-labelledby='vouchers')
              router-link.nav-link(:to="{ name: 'OrderDiscountCodesList' }") Wertgutscheine
              router-link.nav-link(:to="{ name: 'OrderVoucherCodesList' }") Auswahlgutscheine
          //li.nav-item.dropdown
            router-link.nav-link(:to="{ name: 'OrderDiscountCodesList' }") Wertgutscheine
        ul.navbar-nav.ms-auto
          li.nav-item
            a.nav-link(@click="logout") abmelden
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  methods: {
    logout() {
      try {
        this.$session.destroy();
        this.$router.push({ name: "Login" });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  }
}
</script>
