<template lang="pug">
.create-marketing-promo-contest-modal.p-4
  .container-fluid
    .row
      .col-12
        h5 Neues Gewinnspiel

      .col-12
        .form-group
          label.form-label Name*
          input.form-control(v-model="name")

      .col-12
        .form-group
          label.form-label Beschreibung
          textarea-autosize.form-control(v-model="description")

      .col-6
        .form-group
          label.form-label Registration von*
          input.form-control(type="datetime-local" :value="registrationFrom | formatInputDateTime" @change="registrationFrom = $event.target.value;")
      .col-6
        .form-group
          label.form-label Registration bis*
          input.form-control(type="datetime-local" :value="registrationTill | formatInputDateTime" @change="registrationTill = $event.target.value;")

      .col-6
        .form-group
          label.form-label Preise gültig von*
          input.form-control(type="datetime-local" :value="prizeEnabledFrom | formatInputDateTime" @change="prizeEnabledFrom = $event.target.value;")
      .col-6
        .form-group
          label.form-label Preise gültig bis*
          input.form-control(type="datetime-local" :value="prizeEnabledTill | formatInputDateTime" @change="prizeEnabledTill = $event.target.value;")

      .col-12.mt-2

      .col-6.mt-2
        button.button.button-primary.button-tdays.w-100(type='button' @click="createMarketingPromoContest")
          | speichern
      .col-6.mt-2
        button.button.button-primary.button-tpics.w-100(type='button' @click="$emit('close')")
          | schließen

</template>

<script>
import EventBus from "@/views/event-bus";
import {MarketingPromoContests} from "@/graphql/MarketingPromoContests.ts";

export default {
  name: "CreateMarketingPromoContestModal",
  data() {
    return {
      name: "",
      description: "",
      registrationFrom: undefined,
      registrationTill: undefined,
      prizeEnabledFrom: undefined,
      prizeEnabledTill: undefined,
    }
  },
  methods: {
    createMarketingPromoContest() {
      try {
        if (!this.name) {
          return this.$alert("Bitte geben Sie einen Namen an.");
        }
        if (!this.registrationFrom) {
          return this.$alert("Bitte geben Sie einen Registrationsstart an.")
        }
        if (!this.registrationTill) {
          return this.$alert("Bitte geben Sie einen Registrationsende an.")
        }
        if (!this.prizeEnabledTill) {
          return this.$alert("Bitte geben Sie einen Preisgültigkeitsstart an.")
        }
        if (!this.prizeEnabledTill) {
          return this.$alert("Bitte geben Sie einen Preisgültigkeitsende an.")
        }

        const marketingPromoContest = {
          Name: this.name,
          Description: this.description,
          RegistrationFrom: this.registrationFrom,
          RegistrationTill: this.registrationTill,
          PrizeEnabledFrom: this.prizeEnabledFrom,
          PrizeEnabledTill: this.prizeEnabledTill,
        };
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.mutate({
          mutation: MarketingPromoContests.Mutations.CreateMarketingPromoContest,
          variables: {
            marketingPromoContest: marketingPromoContest,
          }
        })
        .then(({ data }) => {
          EventBus.$emit("changeLoadingState", false);
          if (!data || !data.createMarketingPromoContest) {
            return this.$alert("Es konnte kein Gewinnspiel erstellt werden.");
          }
          EventBus.$emit("updateMarketingPromoContestsList");
          this.$alert("Das Gewinnspiel wurde erfolgreich erstellt.");
          this.$emit("close");
        })
        .catch((e) => {
          EventBus.$emit("changeLoadingState", false);
          console.log(e);
          this.$alert(e.message);
        })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  },
}
</script>

<style scoped>
</style>
