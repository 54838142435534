<template lang="pug">
  .update-marketing-promo-contest-prize-modal.p-4
    .container-fluid
      .row(v-if="marketingPromoContestPrize")
        .col-12
          h5 Artikelpreis bearbeiten

        .col-12.mt-2(v-if="item")
          b {{ item.CustomAttributes.Name1 }}
          p {{ item.CustomAttributes.Custom_Product_Arrangement }}

        .col-12.mt-2
          .form-group
            label.form-label Item-Id*
            input.form-control(v-model="marketingPromoContestPrize.ItemId" @change="loadItem")

        .col-12
          .form-group
            label.form-label Headline*
            input.form-control(v-model="marketingPromoContestPrize.HtmlHeadline")

        .col-12.mt-2
          .form-group
            label.form-label Subline*
            input.form-control(v-model="marketingPromoContestPrize.HtmlSubline")

        .col-12.mt-2
          .form-group
            label.form-label Beschreibung*
            textarea-autosize.form-control(v-model="marketingPromoContestPrize.HtmlDescription")

        .col-12.mt-2
          .form-group
            label.form-label Bild-Url*
            input.form-control(v-model="marketingPromoContestPrize.ImageUrl")

        .col-12.mt-2
          .form-group
            label.form-label Preis ziehen am*
            input.form-control(type="datetime-local" :value="marketingPromoContestPrize.DrawPrizeAt | formatInputDateTime" @change="marketingPromoContestPrize.DrawPrizeAt = $event.target.value;")

        .col-6.mt-2
          button.button.button-primary.button-tdays.w-100(type='button' @click="updateMarketingPromoContestPrize")
            | speichern
        .col-6.mt-2
          button.button.button-primary.button-tpics.w-100(type='button' @click="$emit('close')")
            | schließen

</template>

<script>
import EventBus from "@/views/event-bus";
import {MarketingPromoContestPrizes} from "@/graphql/MarketingPromoContestPrizes.ts";
import {Items} from "@/graphql/Items.ts";

export default {
  name: "UpdateMarketingPromoContestModal",
  emits:["close"],
  props: {
    marketingPromoContestPrizeId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.loadMarketingPromoContestPrize();
  },
  data() {
    return {
      item: undefined,
      marketingPromoContestPrize: undefined,
    }
  },
  methods: {
    async loadMarketingPromoContestPrize() {
      try {
        EventBus.$emit("changeLoadingState", true);
        this.marketingPromoContestPrize = await this.$apollo.query({
          query: MarketingPromoContestPrizes.Queries.MarketingPromoContestPrize,
          fetchPolicy: "no-cache",
          variables: {
            id: this.marketingPromoContestPrizeId,
          }
        })
        .then((response) => response?.data?.marketingPromoContestPrize);
        await this.loadItem();
      } catch (e) {
        console.log(e);
        await this.$alert(e.message);
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    },
    async updateMarketingPromoContestPrize() {
      try {
        if (!this.item) {
          return this.$alert("Bitte vergeben Sie eine gültige Artikel-Id.");
        }
        if (!this.marketingPromoContestPrize.ItemId) {
          return this.$alert("Bitte vergeben Sie eine Artikel-Id.");
        }
        if (!this.marketingPromoContestPrize.HtmlHeadline) {
          return this.$alert("Bitte vergeben Sie eine Headline.");
        }
        if (!this.marketingPromoContestPrize.HtmlSubline) {
          return this.$alert("Bitte vergeben Sie eine Subline.");
        }
        if (!this.marketingPromoContestPrize.HtmlDescription) {
          return this.$alert("Bitte vergeben Sie eine Beschreibung.");
        }
        if (!this.marketingPromoContestPrize.DrawPrizeAt) {
          return this.$alert("Bitte vergeben Sie einen Auslosezeitpunkt.");
        }

        EventBus.$emit("changeLoadingState", true);
        const prize = await this.$apollo.mutate({
          mutation: MarketingPromoContestPrizes.Mutations.UpdateMarketingPromoContestPrize,
          variables: {
            marketingPromoContestPrize: this.marketingPromoContestPrize,
          }
        });
        await this.$alert("Der Preis wurde erfolgreich gespeichert");
        this.$emit("close", prize);
      } catch (e) {
        console.log(e);
        await this.$alert("Beim speichern des Preises ist ein Fehler aufgetreten");
      } finally {
        EventBus.$emit("changeLoadingState", false);
      }
    },
    async loadItem() {
      if (!this.marketingPromoContestPrize.ItemId) {
        return;
      }
      this.$apollo.query({
        query: Items.Queries.Item,
        variables: {
          id: this.marketingPromoContestPrize.ItemId,
        }
      })
        .then(({data}) => {
          if (!data || !data.item) {
            return this.$alert("Der Artikel konnte nicht geladen werden.");
          }
          this.item = data.item;
        })
        .catch((e) => {
          console.log(e);
          this.$alert(e.message);
        });
    }
  },
}
</script>

<style scoped>
</style>
