<template lang="pug">
.order-voucher-code-details
  .container-fluid
    .row(v-if="orderVoucherCode")
      .col-12
        h4 Auswahlgutscheindetails

      .col-4
        .form-group
          label.form-label(for="Code") Code
          input.form-control#Code(type="text" :value="orderVoucherCode.Code" disabled)
      .col-4
        .form-group
          label.form-label(for="EndOfLiveAt") Gültigkeitsdatum
          input.form-control#EndOfLiveAt(type="datetime-local" :value="orderVoucherCode.EndOfLiveAt | formatInputDateTime" @change="orderVoucherCode.EndOfLiveAt = $event.target.value;" :disabled="orderVoucherCode.RedeemedAt")
      .col-4
        label.form-label &nbsp;
        ModelListSelect.rounded-0(
          :list="selectionVouchers"
          option-value="id"
          option-text="Name"
          v-model="orderVoucherCode.SelectionVoucherId"
          placeholder="Bitte wählen Sie einen Auswahlgutschein aus"
          :class="{ 'disabled': orderVoucherCode.RedeemedAt }"
        )

      .col-4.mt-2
        .form-group
          label.form-label(for="RedeemedAt") Eingelöst am
          input.form-control#RedeemedAt(type="datetime-local" :value="orderVoucherCode.RedeemedAt | formatInputDateTime" disabled)
      .col-4.mt-2
        .form-group
          label.form-label(for="RedeemedWithOrderNumber") Eingelöst mit Auftragsnummer
          input.form-control#RedeemedWithOrderNumber(type="text" :value="orderVoucherCode.RedeemedWithOrderNumber" disabled)
      .col-4.mt-2
        .form-group
          label.form-label(for="RedeemedWithOrderRowId") Eingelöst mit Auftragsartikel
          input.form-control#RedeemedWithOrderRowId(type="text" :value="orderVoucherCode.RedeemedWithOrderRowId" disabled)

      .col-6.mt-2
        label.form-label(for="IsDisabled") &nbsp;
        a.button.button-primary.w-100#IsDisabled(@click="setIsDisabled" :disabled="orderVoucherCode.RedeemedAt" :class="{ 'button-tpics': orderVoucherCode.IsDisabled, 'button-tdays': !orderVoucherCode.IsDisabled, 'disabled': orderVoucherCode.RedeemedAt }")
          | {{ orderVoucherCode.IsDisabled ? 'deaktiviert' : 'aktiviert' }}

      .col-6.mt-2
        .form-group
          label.form-label(for="DisabledAt") Deaktiviert am
          input.form-control#DisabledAt(type="datetime-local" :value="orderVoucherCode.DisabledAt | formatInputDateTime" disabled)

      .col-12.mt-3
        .float-right
          button.button.button-primary.button-tdays(@click="updateOrderVoucherCode") speichern

</template>

<script>
import {OrderVoucherCodes} from "@/graphql/OrderVoucherCodes.ts";
import {SelectionVouchers} from "@/graphql/SelectionVouchers.ts";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "OrderVoucherCodeDetails",
  props: {
    orderVoucherCodeId: {
      type: String,
      required: true,
    },
  },
  components: {
    ModelListSelect,
  },
  data() {
    return {
      orderVoucherCode: undefined,
      selectionVouchers: [],
    };
  },
  mounted() {
    this.loadOrderVoucherCode();
  },
  methods: {
    setIsDisabled() {
      try {
        if (this.orderVoucherCode.RedeemedAt) {
          return;
        }
        this.orderVoucherCode.IsDisabled = !this.orderVoucherCode.IsDisabled;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadOrderVoucherCode() {
      try {
        this.$apollo.query({
          query: OrderVoucherCodes.Queries.OrderVoucherCodeById,
          fetchPolicy: "no-cache",
          variables: {
            id: this.orderVoucherCodeId,
          }
        })
            .then(({data}) => {
              if (!data || !data.orderVoucherCodeById) {
                return this.$alert("Es konnte kein Auswahlgutschein geladen werden.");
              }
              this.orderVoucherCode = data.orderVoucherCodeById;
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    updateOrderVoucherCode() {
      try {
        this.$apollo.mutate({
          mutation: OrderVoucherCodes.Mutations.UpdateOrderVoucherCode,
          variables: {
            orderVoucherCode: this.orderVoucherCode,
          }
        })
            .then(({data}) => {
              if (!data || !data.updateOrderVoucherCode) {
                return this.$alert("Der Auswahlgutschein konnte nicht gespeichert werden.");
              }
              if (data.updateOrderVoucherCode.Message) {
                this.$alert(data.updateOrderVoucherCode.Message);
              }
              this.loadOrderVoucherCode();
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
  apollo: {
    selectionVouchers: {
      query: SelectionVouchers.Queries.SelectionVouchers,
    }
  },
};
</script>
