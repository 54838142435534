const baseConfig = {
    graphQl: {
        uri: "http://localhost:5225/graphql",
    },
    runMode: process.env.NODE_ENV ?? 'local',
}

export default () => {
    switch (process.env.NODE_ENV) {
        default:
            return {
                ...baseConfig, ...{
                }
            };
        case "staging":
            return {
                ...baseConfig, ...{
                    graphQl: {
                        uri: "https://moloss-apollo.hub.touri.dev/graphql",
                    },
                }
            };
        case "production":
            return {
                ...baseConfig, ...{
                    graphQl: {
                        uri: "https://moloss-apollo.htzn-cap-01.touri.dev/graphql",
                    },
                }
            };
    }
};
