import gql from "graphql-tag";

export const CategorySliderItems = {
    Mutations: {
        CreateCategorySliderItem: gql`
        mutation createCategorySliderItem($categoryId: ID, $itemId: ID) {
            createCategorySliderItem(categoryId: $categoryId, itemId: $itemId) {
                IsSuccess
                Message
            }
        }`,
        DeleteCategorySliderItem: gql`
        mutation deleteCategorySliderItem($categoryId: ID, $itemId: ID) {
            deleteCategorySliderItem(categoryId: $categoryId, itemId: $itemId) {
                IsSuccess
                Message
            }
        }`,
    }
};
