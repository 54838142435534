import gql from "graphql-tag";

export const CampaignTypes = {
    Queries: {
        CampaignTypes: gql`
        query campaignTypes {
            campaignTypes {
                id
                Name
                From
                To
                IsFinished
            }
        }`,
    }
};
