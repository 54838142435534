import Vue from "vue";
import VueRouter from "vue-router";
import VueApollo from "vue-apollo";

Vue.use(VueRouter);
Vue.use(VueApollo);

import Home from "@/views/home/Home";
import HostsList from "@/views/hosts/HostsList";
import HostDetails from "@/views/hosts/HostDetails";
import CategoriesList from "@/views/categories/CategoriesList";
import CategoryDetails from "@/views/categories/CategoryDetails";
import OrderDiscountCodesList from "@/views/orderDiscountCodes/OrderDiscountCodesList";
import OrderDiscountCodeDetails from "@/views/orderDiscountCodes/OrderDiscountCodeDetails";
import OrderVoucherCodesList from "@/views/orderVoucherCodes/OrderVoucherCodesList";
import OrderVoucherCodeDetails from "@/views/orderVoucherCodes/OrderVoucherCodeDetails";
import SelectionVouchersList from "@/views/selectionVouchers/SelectionVouchersList";
import SelectionVoucherDetails from "@/views/selectionVouchers/SelectionVoucherDetails";
import Login from "@/views/login/Login";
import MarketingPromoContestsList from "@/views/marketingPromoContests/MarketingPromoContestsList";
import MarketingPromoContestDetails from "@/views/marketingPromoContests/MarketingPromoContestDetails";
import ItemDetails from "@/views/items/ItemDetails.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/hosts",
        name: "HostsList",
        component: HostsList,
    },
    {
        path: "/hosts/:hostId",
        name: "HostDetails",
        component: HostDetails,
        props: true,
    },
    {
        path: "/hosts/:hostId/items/:itemId",
        name: "ItemDetails",
        component: ItemDetails,
        props: true,
    },
    {
        path: "/categories",
        name: "CategoriesList",
        component: CategoriesList,
    },
    {
        path: "/categories/:categoryId",
        name: "CategoryDetails",
        component: CategoryDetails,
        props: true,
    },
    {
        path: "/orderDiscountCodes",
        name: "OrderDiscountCodesList",
        component: OrderDiscountCodesList,
    },
    {
        path: "/orderDiscountCodes/:orderDiscountCodeId",
        name: "OrderDiscountCodeDetails",
        component: OrderDiscountCodeDetails,
        props: true,
    },
    {
        path: "/orderVoucherCodes",
        name: "OrderVoucherCodesList",
        component: OrderVoucherCodesList,
    },
    {
        path: "/orderVoucherCodes/:orderVoucherCodeId",
        name: "OrderVoucherCodeDetails",
        component: OrderVoucherCodeDetails,
        props: true,
    },
    {
        path: "/selectionVouchers",
        name: "SelectionVouchersList",
        component: SelectionVouchersList,
    },
    {
        path: "/selectionVouchers/:selectionVoucherId",
        name: "SelectionVoucherDetails",
        component: SelectionVoucherDetails,
        props: true,
    },
    {
        path: "/marketingPromoContests",
        name: "MarketingPromoContestsList",
        component: MarketingPromoContestsList,
    },
    {
        path: "/marketingPromoContests/:marketingPromoContestId",
        name: "MarketingPromoContestDetails",
        component: MarketingPromoContestDetails,
        props: true,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
];

const router = new VueRouter({
    routes,
    linkExactActiveClass: "active",
});


router.beforeEach((to, from, next) => {
    if (to.name == "Login") {
        return next();
    }
    // needs to login
    if (
        !router.app.$session.exists() || !router.app.$session.has("token")
    ) {
        router.app.$session.destroy();
        return next({name: "Login"});
    }
    next();
});

export default router;
