import gql from "graphql-tag";

export const Items = {
    Queries: {
        CurrentHighlightItem: gql`
            query highlightItem {
                highlightItem {
                    id
                    SystemItemId
                    RegularPrice
                    DiscountedPrice
                    CurrentPrice
                    AdvertisedTill
                    AdvertisedFrom
                    Persons
                    Catering
                    Nights
                    HostId
                    IsInLiveShopping
                    IsHighlightOfTheWeekOnStartPage
                    Quantity
                    CustomAttributes {
                        UrlPath
                        Custom_HostId
                        Custom_HostId_Homepage
                        Custom_Longitude
                        Custom_Latitude
                        Custom_Product_Images
                        Custom_ImagePositionUsage
                        Custom_LiveShopping_Headline
                        Custom_LiveShopping_Subline
                        Custom_Slider_Images
                        HighlightImages
                    }
                }
            }`,
        FutureHighlightItems: gql`
        query futureHighlightItems {
            futureHighlightItems {
                id
                SystemItemId
                Persons
                Nights
                Catering
                RegularPrice
                DiscountedPrice
                CurrentPrice
                IsTouriTippsItem
                Quantity
                AdvertisedFrom
                AdvertisedTill
                IsInLiveShopping
                IsHighlightOfTheWeekOnStartPage
                HostId
                CustomAttributes {
                    Name1
                    Name2
                    Name3
                    UrlPath
                    MetaKeywords
                    MetaDescription
                    ItemText
                    TechnicalText
                    Custom_IsTouriBookCalendarActive
                    Custom_VoucherPersons
                    Custom_HostStarRating
                    Custom_HostCateringService
                    Custom_Nights
                    Custom_HostId
                    Custom_BlauItemId
                    Custom_HostId_Homepage
                    Custom_Longitude
                    Custom_Latitude
                    Custom_Hotel_Address
                    Custom_Host_Address
                    Custom_Hotel_Title
                    Custom_Hotel_Subtitle
                    Custom_Hotel_Description
                    Custom_Product_Headline01
                    Custom_Product_Headline02
                    Custom_Product_Headline03
                    Custom_Product_Description01
                    Custom_Product_Description02
                    Custom_Product_Arrangement
                    Custom_Product_HotelServices
                    Custom_Product_TouristServices
                    Custom_Product_ServicesExtra
                    Custom_Product_Bookability
                    Custom_Product_BookabilityInfo
                    Custom_Product_VimeoURL
                    Custom_Product_Images
                    Custom_Product_SearchCriterias
                    Custom_ImagePositionUsage
                    Custom_Slider_Img_Url
                    Custom_Slider_Img_Vibrant_Color
                    Custom_Hotel_Type
                    Custom_Slider_Text
                    Custom_Region_City
                    Custom_Hotel_Accommodation
                    Custom_Product_Specials
                    Custom_Hotel_Name
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    Custom_Flat_Filter_Criteria
                    Custom_Hotel_Description_02
                    Custom_Hotel_Description_03
                    Custom_Hotel_Description_04
                    Custom_MetaTitle
                    Custom_Img_Banner_Html_Snippet
                    Custom_Slider_Images
                    Custom_Product_Note
                    HighlightImages
                }
            }
        }`,
        Item: gql`
        query item($id: ID) {
            item(id: $id) {
                id
                SystemItemId
                Persons
                Nights
                Catering
                RegularPrice
                DiscountedPrice
                CurrentPrice
                IsTouriTippsItem
                Quantity
                AdvertisedFrom
                AdvertisedTill
                IsInLiveShopping
                IsHighlightOfTheWeekOnStartPage
                HostId
                CustomAttributes {
                    Name1
                    Name2
                    Name3
                    UrlPath
                    MetaKeywords
                    MetaDescription
                    ItemText
                    TechnicalText
                    Custom_IsTouriBookCalendarActive
                    Custom_VoucherPersons
                    Custom_HostStarRating
                    Custom_HostCateringService
                    Custom_Nights
                    Custom_HostId
                    Custom_BlauItemId
                    Custom_HostId_Homepage
                    Custom_Longitude
                    Custom_Latitude
                    Custom_Hotel_Address
                    Custom_Host_Address
                    Custom_Hotel_Title
                    Custom_Hotel_Subtitle
                    Custom_Hotel_Description
                    Custom_Product_Headline01
                    Custom_Product_Headline02
                    Custom_Product_Headline03
                    Custom_Product_Description01
                    Custom_Product_Description02
                    Custom_Product_Arrangement
                    Custom_Product_HotelServices
                    Custom_Product_TouristServices
                    Custom_Product_ServicesExtra
                    Custom_Product_Bookability
                    Custom_Product_BookabilityInfo
                    Custom_Product_VimeoURL
                    Custom_Product_Images
                    Custom_Product_SearchCriterias
                    Custom_ImagePositionUsage
                    Custom_Slider_Img_Url
                    Custom_Slider_Img_Vibrant_Color
                    Custom_Hotel_Type
                    Custom_Slider_Text
                    Custom_Region_City
                    Custom_Hotel_Accommodation
                    Custom_Product_Specials
                    Custom_Hotel_Name
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    Custom_Flat_Filter_Criteria
                    Custom_Hotel_Description_02
                    Custom_Hotel_Description_03
                    Custom_Hotel_Description_04
                    Custom_MetaTitle
                    Custom_Img_Banner_Html_Snippet
                    Custom_Slider_Images
                    Custom_Product_Note
                    HighlightImages
                }
            }
        }`,
        SystemItem: gql`
        query systemItem($systemItemId: ID) {
            systemItem(systemItemId: $systemItemId) {
                id
                SystemItemId
                Persons
                Nights
                Catering
                RegularPrice
                DiscountedPrice
                CurrentPrice
                Quantity
                AdvertisedFrom
                AdvertisedTill
                IsInLiveShopping
                IsHighlightOfTheWeekOnStartPage
                CustomAttributes {
                    Name1
                    Name2
                    Name3
                    MetaKeywords
                    MetaDescription
                    UrlPath
                    ItemText
                    TechnicalText
                    Custom_IsTouriBookCalendarActive
                    Custom_VoucherPersons
                    Custom_HostStarRating
                    Custom_HostCateringService
                    Custom_Nights
                    Custom_HostId
                    Custom_BlauItemId
                    Custom_HostId_Homepage
                    Custom_Longitude
                    Custom_Latitude
                    Custom_Hotel_Address
                    Custom_Host_Address
                    Custom_Hotel_Title
                    Custom_Hotel_Subtitle
                    Custom_Hotel_Description
                    Custom_Product_Headline01
                    Custom_Product_Headline02
                    Custom_Product_Headline03
                    Custom_Product_Description01
                    Custom_Product_Description02
                    Custom_Product_Arrangement
                    Custom_Product_HotelServices
                    Custom_Product_TouristServices
                    Custom_Product_ServicesExtra
                    Custom_Product_Bookability
                    Custom_Product_BookabilityInfo
                    Custom_Product_VimeoURL
                    Custom_Product_Images
                    Custom_Product_SearchCriterias
                    Custom_ImagePositionUsage
                    Custom_Slider_Img_Url
                    Custom_Slider_Img_Vibrant_Color
                    Custom_Hotel_Type
                    Custom_Slider_Text
                    Custom_Region_City
                    Custom_Hotel_Accommodation
                    Custom_Product_Specials
                    Custom_Hotel_Name
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    Custom_Flat_Filter_Criteria
                    Custom_Hotel_Description_02
                    Custom_Hotel_Description_03
                    Custom_Hotel_Description_04
                    Custom_MetaTitle
                    Custom_Img_Banner_Html_Snippet
                    Custom_Slider_Images
                    Custom_Product_Note
                    HighlightImages
                }
            }
        }`,
        CategoryItems: gql`
        query categoryItems($categoryId: ID) {
            categoryItems(categoryId: $categoryId) {
                id
                Name
                HostId
            }
        }`,
        CategorySliderItems: gql`
        query categorySliderItems($categoryId: ID) {
            categorySliderItems(categoryId: $categoryId) {
                id
                Name
                SystemItemId
                Persons
                Nights
                Catering
                RegularPrice
                DiscountedPrice
                CurrentPrice
                Quantity
                AdvertisedFrom
                AdvertisedTill
                IsInLiveShopping
                IsHighlightOfTheWeekOnStartPage
                HostId
                IsTouriTippsItem
                CustomAttributes {
                    Name1
                    Name2
                    Name3
                    MetaKeywords
                    MetaDescription
                    UrlPath
                    ItemText
                    TechnicalText
                    Custom_IsTouriBookCalendarActive
                    Custom_VoucherPersons
                    Custom_HostStarRating
                    Custom_HostCateringService
                    Custom_Nights
                    Custom_HostId
                    Custom_BlauItemId
                    Custom_HostId_Homepage
                    Custom_Longitude
                    Custom_Latitude
                    Custom_Hotel_Address
                    Custom_Host_Address
                    Custom_Hotel_Title
                    Custom_Hotel_Subtitle
                    Custom_Hotel_Description
                    Custom_Product_Headline01
                    Custom_Product_Headline02
                    Custom_Product_Headline03
                    Custom_Product_Description01
                    Custom_Product_Description02
                    Custom_Product_Arrangement
                    Custom_Product_HotelServices
                    Custom_Product_TouristServices
                    Custom_Product_ServicesExtra
                    Custom_Product_Bookability
                    Custom_Product_BookabilityInfo
                    Custom_Product_VimeoURL
                    Custom_Product_Images
                    Custom_Product_SearchCriterias
                    Custom_ImagePositionUsage
                    Custom_Slider_Img_Url
                    Custom_Slider_Img_Vibrant_Color
                    Custom_Hotel_Type
                    Custom_Slider_Text
                    Custom_Region_City
                    Custom_Hotel_Accommodation
                    Custom_Product_Specials
                    Custom_Hotel_Name
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    Custom_Flat_Filter_Criteria
                    Custom_Hotel_Description_02
                    Custom_Hotel_Description_03
                    Custom_Hotel_Description_04
                    Custom_MetaTitle
                    Custom_Img_Banner_Html_Snippet
                    Custom_Slider_Images
                    Custom_Product_Note
                    HighlightImages
                }
            }
        }`,
        SelectionVoucherItems: gql`
        query selectionVoucherItems($selectionVoucherId: ID) {
            selectionVoucherItems(selectionVoucherId: $selectionVoucherId) {
                id
                Name
                SystemItemId
                Persons
                Nights
                Catering
                RegularPrice
                DiscountedPrice
                CurrentPrice
                Quantity
                AdvertisedFrom
                AdvertisedTill
                IsInLiveShopping
                IsHighlightOfTheWeekOnStartPage
                IsTouriTippsItem
                CustomAttributes {
                    Name1
                    Name2
                    Name3
                    MetaKeywords
                    MetaDescription
                    UrlPath
                    ItemText
                    TechnicalText
                    Custom_IsTouriBookCalendarActive
                    Custom_VoucherPersons
                    Custom_HostStarRating
                    Custom_HostCateringService
                    Custom_Nights
                    Custom_HostId
                    Custom_BlauItemId
                    Custom_HostId_Homepage
                    Custom_Longitude
                    Custom_Latitude
                    Custom_Hotel_Address
                    Custom_Host_Address
                    Custom_Hotel_Title
                    Custom_Hotel_Subtitle
                    Custom_Hotel_Description
                    Custom_Product_Headline01
                    Custom_Product_Headline02
                    Custom_Product_Headline03
                    Custom_Product_Description01
                    Custom_Product_Description02
                    Custom_Product_Arrangement
                    Custom_Product_HotelServices
                    Custom_Product_TouristServices
                    Custom_Product_ServicesExtra
                    Custom_Product_Bookability
                    Custom_Product_BookabilityInfo
                    Custom_Product_VimeoURL
                    Custom_Product_Images
                    Custom_Product_SearchCriterias
                    Custom_ImagePositionUsage
                    Custom_Slider_Img_Url
                    Custom_Slider_Img_Vibrant_Color
                    Custom_Hotel_Type
                    Custom_Slider_Text
                    Custom_Region_City
                    Custom_Hotel_Accommodation
                    Custom_Product_Specials
                    Custom_Hotel_Name
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    Custom_Flat_Filter_Criteria
                    Custom_Hotel_Description_02
                    Custom_Hotel_Description_03
                    Custom_Hotel_Description_04
                    Custom_MetaTitle
                    Custom_Img_Banner_Html_Snippet
                    Custom_Slider_Images
                    Custom_Product_Note
                    HighlightImages
                }
            }
        }`,
        AllItems: gql`
        query allItems {
            allItems {
                id
                SystemItemId
                Name
                Persons
                Nights
                Catering
                RegularPrice
                DiscountedPrice
                CurrentPrice
                Quantity
                AdvertisedFrom
                AdvertisedTill
                IsInLiveShopping
                IsHighlightOfTheWeekOnStartPage
                IsTouriTippsItem
                CustomAttributes {
                    Name1
                    Name2
                    Name3
                    MetaKeywords
                    MetaDescription
                    UrlPath
                    ItemText
                    TechnicalText
                    Custom_IsTouriBookCalendarActive
                    Custom_VoucherPersons
                    Custom_HostStarRating
                    Custom_HostCateringService
                    Custom_Nights
                    Custom_HostId
                    Custom_BlauItemId
                    Custom_HostId_Homepage
                    Custom_Longitude
                    Custom_Latitude
                    Custom_Hotel_Address
                    Custom_Host_Address
                    Custom_Hotel_Title
                    Custom_Hotel_Subtitle
                    Custom_Hotel_Description
                    Custom_Product_Headline01
                    Custom_Product_Headline02
                    Custom_Product_Headline03
                    Custom_Product_Description01
                    Custom_Product_Description02
                    Custom_Product_Arrangement
                    Custom_Product_HotelServices
                    Custom_Product_TouristServices
                    Custom_Product_ServicesExtra
                    Custom_Product_Bookability
                    Custom_Product_BookabilityInfo
                    Custom_Product_VimeoURL
                    Custom_Product_Images
                    Custom_Product_SearchCriterias
                    Custom_ImagePositionUsage
                    Custom_Slider_Img_Url
                    Custom_Slider_Img_Vibrant_Color
                    Custom_Hotel_Type
                    Custom_Slider_Text
                    Custom_Region_City
                    Custom_Hotel_Accommodation
                    Custom_Product_Specials
                    Custom_Hotel_Name
                    Custom_LiveShopping_Headline
                    Custom_LiveShopping_Subline
                    Custom_Flat_Filter_Criteria
                    Custom_Hotel_Description_02
                    Custom_Hotel_Description_03
                    Custom_Hotel_Description_04
                    Custom_MetaTitle
                    Custom_Img_Banner_Html_Snippet
                    Custom_Slider_Images
                    Custom_Product_Note
                    HighlightImages
                }
            }
        }`,
        hostItems: gql`
            query HostItems($hostId: ID) {
                hostItems(hostId: $hostId) {
                    id
                    Name
                    SystemItemId
                    Persons
                    Nights
                    Catering
                    RegularPrice
                    DiscountedPrice
                    LiveShoppingPrice
                    CurrentPrice
                    Quantity
                    AdvertisedFrom
                    AdvertisedTill
                    IsInLiveShopping
                    StockAvailableQuantityRegularPrice
                    StockReservedQuantityRegularPrice
                    StockAvailableQuantityDealPrice
                    StockReservedQuantityDealPrice
                    IsTouriTippsItem
                    IsHighlightOfTheWeekOnStartPage
                    CustomAttributes {
                        Name1
                        Name2
                        Name3
                        MetaKeywords
                        MetaDescription
                        UrlPath
                        ItemText
                        TechnicalText
                        Custom_IsTouriBookCalendarActive
                        Custom_VoucherPersons
                        Custom_HostStarRating
                        Custom_HostCateringService
                        Custom_Nights
                        Custom_HostId
                        Custom_BlauItemId
                        Custom_HostId_Homepage
                        Custom_Longitude
                        Custom_Latitude
                        Custom_Hotel_Address
                        Custom_Host_Address
                        Custom_Hotel_Title
                        Custom_Hotel_Subtitle
                        Custom_Hotel_Description
                        Custom_Product_Headline01
                        Custom_Product_Headline02
                        Custom_Product_Headline03
                        Custom_Product_Description01
                        Custom_Product_Description02
                        Custom_Product_Arrangement
                        Custom_Product_HotelServices
                        Custom_Product_TouristServices
                        Custom_Product_ServicesExtra
                        Custom_Product_Bookability
                        Custom_Product_BookabilityInfo
                        Custom_Product_VimeoURL
                        Custom_Product_Images
                        Custom_Product_SearchCriterias
                        Custom_ImagePositionUsage
                        Custom_Slider_Img_Url
                        Custom_Slider_Img_Vibrant_Color
                        Custom_Hotel_Type
                        Custom_Slider_Text
                        Custom_Region_City
                        Custom_Hotel_Accommodation
                        Custom_Product_Specials
                        Custom_Hotel_Name
                        Custom_LiveShopping_Headline
                        Custom_LiveShopping_Subline
                        Custom_Flat_Filter_Criteria
                        Custom_Hotel_Description_02
                        Custom_Hotel_Description_03
                        Custom_Hotel_Description_04
                        Custom_MetaTitle
                        Custom_Img_Banner_Html_Snippet
                        Custom_Slider_Images
                        Custom_Product_Note
                        HighlightImages
                        Caterings
                        Accomodations
                    }
                }
            }
        `,
    },
    Mutations: {
        SaveItemHighlightImages: gql`
        mutation saveItemHighlightImages($itemId: ID, $hex: String, $firstImage: String, $secondImage: String, $thirdImage: String, $headline: String, $subline: String) {
            saveItemHighlightImages(itemId: $itemId, hex: $hex, firstImage: $firstImage, secondImage: $secondImage, thirdImage: $thirdImage, headline: $headline, subline: $subline) {
                IsSuccess
                Message
            }
        }`,
        SaveItemSliderImages: gql`
        mutation saveItemSliderImages($itemId: ID, $color: String, $firstImage: String, $secondImage: String, $thirdImage: String) {
            saveItemSliderImages(itemId: $itemId, color: $color, firstImage: $firstImage, secondImage: $secondImage, thirdImage: $thirdImage) {
                IsSuccess
                Message
            }
        }`,
    }
};
