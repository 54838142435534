<template lang="pug">
.selection-vouchers-list
  .container-fluid
    .row
      .col-12
        h4 Auswahlgutscheine
      .col-12
        table.table.w-100
          thead
            tr
              th Id
              th Name
              th Moloss Artikel-ID
              th Produkt-Nr
              th Status
              th #
          tbody
            selection-voucher-table-row(v-for="selectionVoucher of selectionVouchers" :selection-voucher="selectionVoucher")
      .col-4.offset-4
        .text-center
          a.h5(:class="{ 'disabled': selectionVouchers.length == selectionVouchersCount }" @click="loadMoreSelectionVouchers")
            | mehr laden

</template>

<script>
import {SelectionVouchers} from "@/graphql/SelectionVouchers.ts";
import SelectionVoucherTableRow from "@/views/selectionVouchers/SelectionVoucherTableRow.vue";

export default {
  name: "SelectionVouchersList",
  components: {SelectionVoucherTableRow},
  data() {
    return {
      selectionVouchers: [],
      selectionVouchersCount: 0,
      filter: "",
      offset: 0,
      limit: 15,
    };
  },
  mounted() {
    try {
      this.loadSelectionVouchers();
      this.loadSelectionVouchersCount();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    loadSelectionVouchers() {
      try {
        this.$apollo.query({
          query: SelectionVouchers.Queries.SelectionVouchers,
          fetchPolicy: "no-cache",
          variables: {
            filter: this.filter,
            offset: this.offset,
            limit: this.limit,
          }
        })
            .then(({data}) => {
              if (!data || !data.selectionVouchers) {
                return this.$alert("Es konnten keine Auswahlgutscheine geladen werden.");
              }
              this.selectionVouchers = this.selectionVouchers.concat(data.selectionVouchers);
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadSelectionVouchersCount() {
      try {
        this.$apollo.query({
          query: SelectionVouchers.Queries.SelectionVouchersCount,
          fetchPolicy: "no-cache",
          variables: {
            filter: this.filter,
            offset: this.offset,
            limit: this.limit,
          }
        })
            .then(({data}) => {
              if (!data || !data.selectionVouchersCount) {
                return this.$alert("Es konnten keine Auswahlgutscheine geladen werden.");
              }
              this.selectionVouchersCount = data.selectionVouchersCount;
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadMoreSelectionVouchers() {
      try {
        if (this.selectionVouchers.length == this.selectionVouchersCount) {
          return;
        }
        this.offset += this.limit;
        this.loadSelectionVouchers();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  }
}
</script>

<style scoped>

</style>
