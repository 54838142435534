<template lang="pug">
.category-details
  .container-fluid
    .row
      .col-12
        h4 Kategoriedetails
      .col-12
        ul.nav.nav-tabs.mb-4
          li.nav-item
            a.nav-link.rounded-0.active(data-toggle='tab' href='#categorySlider' role='tab' aria-selected='false') Slider
          //li.nav-item
            a.nav-link.rounded-0(data-toggle='tab' href='#categoryData' role='tab' aria-selected='true') Allgemeine Daten

        .tab-content
          //#categoryData.tab-pane.fade
            .container-fluid
              .row
                // GENERAL DATA
                .col-12
                  h5 Allgemeine Daten

          #categorySlider.tab-pane.fade.show.active
            .container.p-0
              .row.mb-3
                .col-12.p-0
                  h5 Slider
                .col-10
                  ModelListSelect.rounded-0(
                    :list="categoryItems"
                    option-value="id"
                    option-text="id"
                    v-model="itemToAdd"
                    placeholder="Bitte wählen Sie ein Kategorie-Artikel aus"
                  )
                .col-2.p-0
                  button.btn-block.button.button-primary.button-tdays.w-100(:disabled="!itemToAdd" :class="{ 'disabled': !itemToAdd }" @click="addCategorySliderItem")
                    | Slider hinzufügen

              CategorySliderItem(:categorySliderItem="categorySliderItem" :categoryId="categoryId" v-for="categorySliderItem in categorySliderItems" :key="categorySliderItem.id" :class="{ 'mt-3': categorySliderItems.indexOf(categorySliderItem) > 0 }")


</template>

<script>
import {Items} from "@/graphql/Items.ts";
import {CategorySliderItems} from "@/graphql/CategorySliderItems.ts";
import CategorySliderItem from "@/views/categories/partials/CategorySliderItem";
import { ModelListSelect } from "vue-search-select";
import {Hosts} from "@/graphql/Hosts.ts";
import EventBus from "@/views/event-bus";

export default {
  name: "CategoryDetails",
  props: {
    categoryId: {
      type: String,
      required: true,
    }
  },
  components: {
    CategorySliderItem,
    ModelListSelect,
  },
  mounted() {
    try {
      EventBus.$on("deleteCategorySliderItem", (itemId) => {
        // eslint-disable-next-line no-debugger
        debugger;
        if (!itemId) {
          return;
        }
        const categorySliderItemToDelete = this.categorySliderItems.find(i => i.id == itemId);
        if (!categorySliderItemToDelete) {
          return;
        }
        this.categorySliderItems.splice(this.categorySliderItems.indexOf(categorySliderItemToDelete), 1);
      });
      this.loadCategoryItems();
      this.loadCategorySliderItems();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  data() {
    return {
      categoryItems: [],
      categorySliderItems: [],
      itemToAdd: undefined,
      selectedImage: "",

      colorPalette: [],
    }
  },
  methods: {
    loadCategoryItems() {
      try {
        this.$apollo.query({
          query: Items.Queries.CategoryItems,
          variables: {
            categoryId: this.categoryId,
          }
        })
            .then(({data}) => {
              if (!data || !data.categoryItems) {
                return;
              }
              this.categoryItems = data.categoryItems;
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadCategorySliderItems() {
      try {
        this.$apollo.query({
          query: Items.Queries.CategorySliderItems,
          fetchPolicy: "no-cache",
          variables: {
            categoryId: this.categoryId,
          }
        })
            .then(({data}) => {
              if (!data || !data.categorySliderItems) {
                return;
              }
              this.categorySliderItems = data.categorySliderItems;
            });
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    getHostImages(item) {
      try {
        if (!item) {
          return undefined;
        }
        this.$apollo.query({
          query: Hosts.Queries.Host,
          variables: {
            id: item.HostId,
          }
        })
            .then(({ data }) => {
              if (!data || !data.host) {
                return this.$alert("Es konnte kein Gastgeber geladen werden.");
              }
              let images = data.host.Images.filter((image) => {
                return !image.Name.toLowerCase().includes('logo') && !image.Name.toLowerCase().includes('ebay') && !image.Name.toLowerCase().includes('slider')
              });
              return images;
            })
            .catch((e) => {
              console.log(e);
              this.$alert(e.message);
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    addCategorySliderItem() {
      try {
        if (!this.itemToAdd) {
          return this.$alert("Bitte wählen Sie zuerst einen Artikel aus.");
        }
        if (this.categorySliderItems.find(i => i.id == this.itemToAdd)) {
          return this.$alert("Der Artikel ist bereits als Slider vorhanden.")
        }

        this.$apollo.query({
          query: Items.Queries.Item,
          fetchPolicy: "no-cache",
          variables: {
            id: this.itemToAdd
          }
        })
            .then(({data}) => {
              if (!data || !data.item) {
                return this.$alert(`Es konnte kein Artikel mit der Id ${this.itemToAdd} geladen werden.`);
              }
              const item = data.item;
              if (!item) {
                return this.$alert(`Es konnte kein Artikel mit der Id ${this.itemToAdd} geladen werden.`);
              }
              this.$apollo.query({
                query: Hosts.Queries.Host,
                variables: {
                  id: item.HostId,
                }
              })
                  .then(({data}) => {
                    if (!data || !data.host) {
                      return this.$alert("Es konnte kein Gastgeber geladen werden.");
                    }
                    let hostImages = data.host.Images.filter((image) => {
                      return !image.Name.toLowerCase().includes('logo') && !image.Name.toLowerCase().includes('ebay') && !image.Name.toLowerCase().includes('slider')
                    });
                    if (hostImages.length < 3) {
                      return this.$alert("Es sind nicht genügend Galeriebilder vorhanden.");
                    }
                    this.$apollo.mutate({
                      mutation: CategorySliderItems.Mutations.CreateCategorySliderItem,
                      variables: {
                        categoryId: this.categoryId,
                        itemId: this.itemToAdd,
                      }
                    })
                        .then(({data}) => {
                          if (!data || !data.createCategorySliderItem) {
                            return this.$alert("Der Slider-Artikel konnte nicht erstellt werden");
                          }
                          if (data.createCategorySliderItem.Message) {
                            this.$alert(data.createCategorySliderItem.Message);
                          }
                          if (data.createCategorySliderItem.IsSuccess) {
                            if (!item.CustomAttributes.Custom_Slider_Images) {
                              item.CustomAttributes.Custom_Slider_Images = {
                                firstImage: hostImages[0].RemoteURL,
                                secondImage: hostImages[1].RemoteURL,
                                thirdImage: hostImages[2].RemoteURL,
                              };
                            }
                            this.categorySliderItems.push(item);
                          }
                        });
                  })
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
}
</script>
