<template lang="pug">
  .create-selection-voucher-json-item.p-4
    .container-fluid
      JsonItemForm(title="Artikel bearbeiten" v-bind="bindItem(selectionVoucherJsonItem)" @save="updateSelectionVoucherJsonItem" @close="$emit('close')")
</template>

<script>
import EventBus from "@/views/event-bus";
import {SelectionVouchers} from "@/graphql/SelectionVouchers.ts";
import {bindItem, default as JsonItemForm} from "@/views/modals/selectionVouchers/partials/JsonItemForm";

export default {
  name: "UpdateSelectionVoucherJsonItem",
  components: {JsonItemForm},
  props: {
    selectionVoucherId: {
      type: String,
      required: true,
    },
    selectionVoucherJsonItem: {
      type: Object,
      required: true,
    },
    selectionVoucherJsonItemIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectionVoucherJsonItemToUpdate: this.selectionVoucherJsonItem,
    }
  },
  methods: {
    bindItem,
    updateSelectionVoucherJsonItem(item) {
      try {
        if(!item) return;
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.mutate({
          mutation: SelectionVouchers.Mutations.UpdateSelectionVoucherJsonItem,
          variables: {
            selectionVoucherJsonItem: item,
            selectionVoucherJsonItemIndex: this.selectionVoucherJsonItemIndex,
            selectionVoucherId: this.selectionVoucherId,
          }
        })
        .then(({ data }) => {
          EventBus.$emit("changeLoadingState", false);
          if (!data || !data.updateSelectionVoucherJsonItem) {
            return this.$alert("Es konnte kein Artikel erstellt werden.");
          }
          if (data.updateSelectionVoucherJsonItem.Message) {
            data.updateSelectionVoucherJsonItem.IsSuccess ? this.$swal(data.updateSelectionVoucherJsonItem.Message) : this.$alert(data.updateSelectionVoucherJsonItem.Message);
          }
          if (data.updateSelectionVoucherJsonItem.IsSuccess) {
            EventBus.$emit("updateSelectionVoucher");
            this.$emit("close");
          }
        })
        .catch((e) => {
          EventBus.$emit("changeLoadingState", false);
          console.log(e);
          this.$alert(e.message);
        })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  }
};
</script>
