<template lang="pug">
.create-order-voucher-code-modal.p-4
  .container-fluid
    .row
      .col-12
        h5 Neuer Auswahlgutschein

      .col-12.mt-2
        ModelListSelect.rounded-0(
          :list="selectionVouchers"
          option-value="id"
          option-text="Name"
          v-model="selectionVoucherId"
          placeholder="Bitte wählen Sie einen Auswahlgutschein aus",
        )

      .col-6.mt-2
        button.button.button-primary.button-tdays.w-100(type='button' @click="createOrderVoucherCode")
          | speichern
      .col-6.mt-2
        button.button.button-primary.button-tpics.w-100(type='button' @click="$emit('close')")
          | schließen

</template>

<script>
import {OrderVoucherCodes} from "@/graphql/OrderVoucherCodes.ts";
import {SelectionVouchers} from "@/graphql/SelectionVouchers.ts";
import EventBus from "@/views/event-bus";
import {ModelListSelect} from "vue-search-select";

export default {
  name: "CreateOrderVoucherCodeModal",
  components: {
    ModelListSelect,
  },
  mounted() {
    try {
      this.loadSelectionVouchers();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  data() {
    return {
      selectionVoucherId: undefined,
      selectionVouchers: [],
    }
  },
  methods: {
    createOrderVoucherCode() {
      try {
        if (!this.selectionVoucherId) {
          return this.$alert("Bitte wählen Sie einen Auswahlgutschein aus.");
        }
        this.$apollo.mutate({
          mutation: OrderVoucherCodes.Mutations.CreateOrderVoucherCode,
          variables: {
            selectionVoucherId: this.selectionVoucherId,
          }
        })
            .then(({data}) => {
              if (!data || !data.createOrderVoucherCode) {
                return this.$alert("Der Wertgutschein konnte nicht gespeichert werden.");
              }
              if (data.createOrderVoucherCode.Message) {
                this.$alert(data.createOrderVoucherCode.Message);
              }
              EventBus.$emit("updateOrderVoucherCodes");
              this.$emit("close");
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadSelectionVouchers() {
      try {
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.query({
          query: SelectionVouchers.Queries.SelectionVouchers,
          variables: {
            filter: "",
            offset: 0,
            limit: 100,
          }
        })
        .then(({ data }) => {
          if (!data || !data.selectionVouchers) {
            return this.$alert("Es konnten keine Auswahlgutscheine geladen werden.");
          }
          this.selectionVouchers = data.selectionVouchers;
        })
        .catch((e) => {
          EventBus.$emit("changeLoadingState", false);
          console.log(e);
          this.$alert(e.message);
        });
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        console.log(e);
        this.$alert(e.message);
      }
    }
  },
}
</script>

<style scoped>
</style>
