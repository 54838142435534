import gql from "graphql-tag";

export const SelectionVouchers = {
    Queries: {
        SelectionVouchers: gql`
        query selectionVouchers($filter: String, $offset: Int, $limit: Int) {
            selectionVouchers(filter: $filter, offset: $offset, limit: $limit) {
                id
                Name
                Headline
                Subline
                RelativeFilePath
                Description
                VoucherImage
                IsActive
                Checklist
                ItemId
            }
        }`,
        SelectionVouchersCount: gql`
        query selectionVouchersCount($filter: String) {
            selectionVouchersCount(filter: $filter)
        }`,
        SelectionVoucherById: gql`
        query selectionVoucherById($id: ID) {
            selectionVoucherById(id: $id) {
                id
                Name
                Headline
                Subline
                RelativeFilePath
                Description
                VoucherImage
                IsActive
                Checklist
                IsStaticSelectionVoucher
                JsonItems
                ItemId
            }
        }`,
    },
    Mutations: {
        UpdateSelectionVoucher: gql`
        mutation updateSelectionVoucher($selectionVoucher: SelectionVouchersInput) {
            updateSelectionVoucher(selectionVoucher: $selectionVoucher) {
                IsSuccess
                Message
            }
        }`,
        CreateSelectionVoucherJsonItem: gql`
        mutation createSelectionVoucherJsonItem($selectionVoucherJsonItem: SelectionVoucherJsonItemsInput, $selectionVoucherId: ID) {
            createSelectionVoucherJsonItem(selectionVoucherJsonItem: $selectionVoucherJsonItem, selectionVoucherId: $selectionVoucherId) {
                IsSuccess
                Message
            }
        }`,
        UpdateSelectionVoucherJsonItem: gql`
        mutation updateSelectionVoucherJsonItem($selectionVoucherJsonItem: SelectionVoucherJsonItemsInput, $selectionVoucherJsonItemIndex: ID, $selectionVoucherId: ID) {
            updateSelectionVoucherJsonItem(selectionVoucherJsonItem: $selectionVoucherJsonItem, selectionVoucherJsonItemIndex: $selectionVoucherJsonItemIndex, selectionVoucherId: $selectionVoucherId) {
                IsSuccess
                Message
            }
        }`,
        DeleteSelectionVoucherJsonItem: gql`
        mutation deleteSelectionVoucherJsonItem($selectionVoucherJsonItemIndex: ID, $selectionVoucherId: ID) {
            deleteSelectionVoucherJsonItem(selectionVoucherJsonItemIndex: $selectionVoucherJsonItemIndex, selectionVoucherId: $selectionVoucherId) {
                IsSuccess
                Message
            }
        }`,
    }
};
