<template lang="pug">
.host-details
  .container-fluid(v-if="backendHost")
    .row
      .col-12
        h4 Gastgeberdetails
      .col-12
        ul.nav.nav-tabs.mb-4
          li.nav-item
            a.nav-link.rounded-0.active(data-toggle='tab' href='#hostData' role='tab' aria-selected='true') Allgemeine Daten
          li.nav-item
            a.nav-link.rounded-0(data-toggle='tab' href='#hostCategories' role='tab' aria-selected='false') Kategorien
          li.nav-item
            a.nav-link.rounded-0(data-toggle='tab' href='#hostItems' role='tab' aria-selected='false') Produkte

        .tab-content
          #hostData.tab-pane.fade.show.active
            .container-fluid
              .row
                // GENERAL DATA
                .col-12
                  h5 Allgemeine Daten
                .col-6
                  input.form-control.mt-2(type="text" v-model="backendHost.Name" placeholder="Name" disabled)
                .col-6
                  input.form-control.mt-2(type="text" v-model="backendHost.CityRegion" placeholder="Region" disabled)
                .col-6
                  input.form-control.mt-2(type="text" v-model="backendHost.CustomAttributes.Title" placeholder="Titel" disabled)
                .col-6
                  input.form-control.mt-2(type="text" v-model="backendHost.CustomAttributes.Subtitle" placeholder="Untertitel" disabled)
                .col-6
                  textarea-autosize.w-100.mt-2(v-model="backendHost.CustomAttributes.HotelDescription" placeholder="Hotel Description" disabled)
                .col-6
                  textarea-autosize.w-100.mt-2(v-model="backendHost.CustomAttributes.EnvironmentDescription" placeholder="Environment Description" disabled)

                .col-12
                  hr

                  // META DATA
                .col-12
                  h5 Metadaten
                .col-6
                  input.form-control(type="text" v-model="backendHost.CustomAttributes.MetaDescription" placeholder="Meta Description" disabled)
                .col-6
                  input.form-control(type="text" v-model="backendHost.CustomAttributes.MetaKeywords" placeholder="Meta Keywords" disabled)

                .col-12
                  hr

                .col-12
                  h5 SEO Daten
                .col-6
                  textarea-autosize.w-100.mt-2(v-model="backendHost.CustomAttributes.SeoDescription" placeholder="SEO Description" disabled)
                .col-6
                  textarea-autosize.w-100.mt-2(v-model="backendHost.CustomAttributes.SeoGeneralDescription" placeholder="SEO General Description" disabled)
                .col-6
                  textarea-autosize.w-100.mt-2(v-model="backendHost.CustomAttributes.SeoEquipmentDescription" placeholder="SEO Equipment Description" disabled)
                .col-6
                  textarea-autosize.w-100.mt-2(v-model="backendHost.CustomAttributes.SeoEnvironmentDescription" placeholder="SEO Environment Description" disabled)

          #hostCategories.tab-pane.fade
            .container-fluid
              .row
                .col-12
                  h5 Kategorien
                .col-10
                  ModelListSelect.rounded-0(
                    :list="categories"
                    option-value="id"
                    option-text="Name"
                    v-model="categoryToAdd"
                    placeholder="Bitte wählen Sie eine Kategorie aus"
                  )
                .col-2
                  button.btn-block.button.button-primary.button-tdays.w-100(:disabled="!categoryToAdd" :class="{ 'disabled': !categoryToAdd }" @click="createCategoryHost")
                    | Kategorie hinzufügen
                .col-12
                  table.table.w-100
                    thead
                      tr
                        th Id
                        th Name
                        th #
                    tbody
                      tr(v-for='hostCategory in hostCategories' :key='hostCategory.id')
                        td {{ hostCategory.id }}
                        td {{ hostCategory.Name }}
                        td
                          button.button.button-primary.button-tpics(@click="deleteCategoryHost(hostCategory.id)")
                            | entfernen
          #hostItems.tab-pane.fade
            ItemsList(:items="items" :key="this.backendHost.Name" :hostId="hostId")
</template>

<script>
import {Hosts} from "@/graphql/Hosts.ts";
import {Categories} from "@/graphql/Categories.ts";
import {CategoryHosts} from "@/graphql/CategoryHosts.ts";
import {ModelListSelect} from "vue-search-select";
import ItemsList from "@/views/items/ItemsList.vue";
import {Items} from "@/graphql/Items.ts";

export default {
  name: "HostDetails",
  props: {
    hostId: {
      type: String,
      required: true,
    }
  },
  components: {
    ModelListSelect,
    ItemsList
  },
  data() {
    return {
      backendHost: undefined,
      categories: [],
      hostCategories: [],
      items: [],
      categoryToAdd: undefined,
    }
  },
  mounted() {
    this.$apollo.query({
      query: Hosts.Queries.Host,
      variables: {
        id: this.hostId,
      }
    })
        .then(({data}) => {
          if (!data || !data.host) {
            return this.$alert("Es konnte kein Gastgeber gefunden werden");
          }
          this.backendHost = data.host;
        });
    this.loadHostCategories();
    this.loadItems();
  },
  methods: {
    loadHostCategories() {
      try {
        this.$apollo.query({
          query: Categories.Queries.HostCategories,
          fetchPolicy: "no-cache",
          variables: {
            hostId: this.hostId,
          },
        })
            .then(({data}) => {
              if (!data || !data.hostCategories) {
                return this.$alert("Es konnten keine Kategoriezuordnungen gefunden werden");
              }
              this.hostCategories = data.hostCategories;
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadItems() {
      try {
        this.$apollo.query({
          query: Items.Queries.hostItems,
          variables: {
            hostId: this.hostId
          }
        })
            .then(({data}) => {
              if (!data || !data.hostItems) {
                return;
              }
              this.items = data.hostItems;
            })
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    createCategoryHost() {
      try {
        if (!this.categoryToAdd) {
          return this.$alert("Es wurde keine Kategorie-Id übergeben.");
        }
        if (this.hostCategories.find(c => c.id == this.categoryToAdd)) {
          return this.$alert("Die Kategorie ist bereits zugeordnet.")
        }
        this.$apollo.mutate({
          mutation: CategoryHosts.Mutations.CreateCategoryHost,
          variables: {
            hostId: this.hostId,
            categoryId: this.categoryToAdd,
          }
        })
            .then(({data}) => {
              if (!data || !data.createCategoryHost) {
                return this.$alert("Die Kategoriezuordnung konnte nicht gespeichert werden.");
              }
              if (data.createCategoryHost.Message) {
                this.$alert(data.createCategoryHost.Message);
              }
              if (data.createCategoryHost.IsSuccess) {
                this.hostCategories.push(this.categoryToAdd);
              }
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    deleteCategoryHost(categoryId) {
      try {
        if (!categoryId) {
          return this.$alert("Es wurde keine Kategorie-Id übergeben.");
        }
        this.$apollo.mutate({
          mutation: CategoryHosts.Mutations.DeleteCategoryHost,
          variables: {
            hostId: this.hostId,
            categoryId: categoryId,
          }
        })
            .then(({data}) => {
              if (!data || !data.deleteCategoryHost) {
                return this.$alert("Die Kategoriezuordnung konnte nicht gelöscht werden.");
              }
              if (data.deleteCategoryHost.Message) {
                this.$alert(data.deleteCategoryHost.Message);
              }
              if (data.deleteCategoryHost.IsSuccess) {
                const categoryToDelete = this.hostCategories.find(c => c.id == categoryId);
                if (!categoryToDelete) {
                  return;
                }
                this.hostCategories.splice(this.hostCategories.indexOf(categoryToDelete), 1);
              }
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  },
  apollo: {
    categories: {
      query: Categories.Queries.Categories,
    }
  }
}
</script>
