<template lang="pug">
  .category-slider-image
    .container-fluid
      .row
        .col-12
          .liveshopping-highlight-wrap
            .row.no-gutters
              .col-12.col-sm-8.order-sm-2.p-0
                .ls-img-1(:style="`background-image: url(${categorySliderItem.CustomAttributes.Custom_Slider_Images.firstImage}large.jpg)`" @click="changeImage(categorySliderItem, 1)")
              .col-12.col-sm-4.order-sm-1.p-0
                .row.no-gutters
                  .col-6.col-sm-12
                    .ls-img-2(:style="`background-image: url(${categorySliderItem.CustomAttributes.Custom_Slider_Images.secondImage}large.jpg)`" @click="changeImage(categorySliderItem, 2)")
                  .col-6.col-sm-12
                    .ls-img-3(:style="`background-image: url(${categorySliderItem.CustomAttributes.Custom_Slider_Images.thirdImage}large.jpg)`" @click="changeImage(categorySliderItem, 3)")
            .row.no-gutters
              .col-12.p-0
                .ls-colored-box.p-3(:style="`background-color: ${categorySliderItem.CustomAttributes.Custom_Slider_Img_Vibrant_Color ? categorySliderItem.CustomAttributes.Custom_Slider_Img_Vibrant_Color : 'rgb(00, 00, 00)'}`")
                  .h1.txt-white.text-left {{categorySliderItem.CustomAttributes.Name2 ? categorySliderItem.CustomAttributes.Name2.trim() : ''}}
                  .h2.txt-white.d-none.d-md-block.text-left {{categorySliderItem.CustomAttributes.Custom_Product_Headline02 ? categorySliderItem.CustomAttributes.Custom_Product_Headline02.trim() : ''}}

            .row.mt-2
              .col-3.p-0(v-for="hostImage of getHostImages(categorySliderItem)")
                img.img-fluid(:src="`${hostImage.RemoteURL}large.jpg`" :class="{ 'overlay': selectedImage == hostImage.RemoteURL }" @click="selectedImage = hostImage.RemoteURL")
              .col-12.p-0.mt-2
                input.form-control.text-center(type="text" v-model="categorySliderItem.CustomAttributes.Custom_Slider_Img_Vibrant_Color" disabled)
              .col-12.p-0(v-for="color in colorPalette")
                button.button.button-primary.button-td-grey.w-100(:style="{ 'background': color.Hex }" @click="categorySliderItem.CustomAttributes.Custom_Slider_Img_Vibrant_Color = 'rgb(' + color.RGB + ')'") rgb({{ color.RGB.toUpperCase() }})
              .col-6.p-0.mt-2
                button.btn-block.button.button-primary.button-tdays.w-100(@click="saveItemSliderImages(categorySliderItem)")
                  | speichern
              .col-6.p-0.mt-2
                button.button.button-primary.button-tpics.w-100(@click="deleteCategorySliderItem(categorySliderItem.id)")
                  | löschen
              .col-12.p-0
                hr

</template>

<script>
import {Items} from "@/graphql/Items.ts";
import {Utils} from "@/graphql/Utils.ts";
import {CategorySliderItems} from "@/graphql/CategorySliderItems.ts";
import {Hosts} from "@/graphql/Hosts.ts";
import EventBus from "@/views/event-bus";

export default {
  name: "CategorySliderItem",
  props: {
    categorySliderItem: {
      type: Object,
      required: true,
    },
    categoryId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    try {
      this.loadHost();
      if (!this.categorySliderItem.CustomAttributes.Custom_Slider_Images) {
        // eslint-disable-next-line vue/no-mutating-props
        this.categorySliderItem.CustomAttributes.Custom_Slider_Images = {};
      }
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  data() {
    return {
      host: undefined,
      colorPalette: [],
      selectedImage: "",
    }
  },
  methods: {
    loadHost() {
      try {
        this.$apollo.query({
          query: Hosts.Queries.Host,
          variables: {
            id: this.categorySliderItem.HostId,
          }
        })
            .then(({data}) => {
              if (!data || !data.host) {
                return this.$alert("Es konnte kein Gastgeber geladen werden.");
              }
              this.host = data.host;
            })
            .catch((e) => {
              console.log(e);
              this.$alert(e.message);
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    getHostImages() {
      try {
        if (!this.host) {
          return undefined;
        }
        // eslint-disable-next-line no-debugger
        debugger;
        let images = this.host.Images.filter((image) => {
          return !image.Name.toLowerCase().includes('logo') && !image.Name.toLowerCase().includes('ebay') && !image.Name.toLowerCase().includes('slider')
        });
        return images;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    changeImage(categorySliderItem, imageNumber) {
      try {
        if (!categorySliderItem) {
          return;
        }
        if (!imageNumber) {
          return;
        }
        if (!this.selectedImage) {
          return;
        }
        switch (imageNumber) {
          case 1:
            categorySliderItem.CustomAttributes.Custom_Slider_Images.firstImage = this.selectedImage;
            break;
          case 2:
            categorySliderItem.CustomAttributes.Custom_Slider_Images.secondImage = this.selectedImage;
            break;
          case 3:
            categorySliderItem.CustomAttributes.Custom_Slider_Images.thirdImage = this.selectedImage;
            break;
        }
        this.selectedImage = undefined;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    saveItemSliderImages(item) {
      try {
        if (!item) {
          return this.$alert("Es wurde kein Artikel zum speichern übergeben.");
        }
        this.$apollo.mutate({
          mutation: Items.Mutations.SaveItemSliderImages,
          fetchPolicy: "no-cache",
          variables: {
            itemId: item.id,
            color: item.CustomAttributes.Custom_Slider_Img_Vibrant_Color,
            firstImage: item.CustomAttributes.Custom_Slider_Images.firstImage,
            secondImage: item.CustomAttributes.Custom_Slider_Images.secondImage,
            thirdImage: item.CustomAttributes.Custom_Slider_Images.thirdImage,
          }
        })
            .then(({data}) => {
              if (!data || !data.saveItemSliderImages) {
                return this.$alert("Die Slider-Bilder konnten nicht gespeichert werden.");
              }
              if (data.saveItemSliderImages.Message) {
                this.$alert(data.saveItemSliderImages.Message);
              }
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    deleteCategorySliderItem() {
      try {
        if (!this.categorySliderItem) {
          return this.$alert("Es wurde keine Artikel-Id übergeben.");
        }
        this.$apollo.mutate({
          mutation: CategorySliderItems.Mutations.DeleteCategorySliderItem,
          variables: {
            categoryId: this.categoryId,
            itemId: this.categorySliderItem.id,
          }
        })
            .then(({data}) => {
              if (!data || !data.deleteCategorySliderItem) {
                return this.$alert("Der Slider-Artikel konnte nicht gelöscht werden.");
              }
              if (data.deleteCategorySliderItem.Message) {
                this.$alert(data.deleteCategorySliderItem.Message);
              }
              if (data.deleteCategorySliderItem.IsSuccess) {
                EventBus.$emit("deleteCategorySliderItem", this.categorySliderItem.id);
              }
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
  apollo: {
    colorPalette: {
      query: Utils.Queries.ColorPalette,
      variables() {
        return {
          imageSource: this.categorySliderItem.CustomAttributes.Custom_Slider_Images.firstImage ? (this.categorySliderItem.CustomAttributes.Custom_Slider_Images.firstImage + "large.jpg") : undefined,
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
