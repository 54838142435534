import gql from "graphql-tag";

export const OrderDiscountCodes = {
    Queries: {
        OrderDiscountCodes: gql`
        query orderDiscountCodes($filter: String, $offset: Int, $limit: Int) {
            orderDiscountCodes(filter: $filter, offset: $offset, limit: $limit) {
                id
                Code
                IsPercentage
                Value
                EndOfLiveAt
                RedeemedAt
            }
        }`,
        OrderDiscountCodesCount: gql`
        query orderDiscountCodesCount($filter: String) {
            orderDiscountCodesCount(filter: $filter)
        }`,
        OrderDiscountCodeById: gql`
        query orderDiscountCodeById($id: ID) {
            orderDiscountCodeById(id: $id) {
                id
                IsPercentage
                Value
                Code
                EndOfLiveAt
                RedeemedAt
                BoughtWithOrderNumber
                BoughtWithOrderRowId
                RedeemedWithOrderNumber,
                RedeemedWithOrderRowId
                DiscountCreatedByOrigin
                InternalNote
                IsDisabled
                DisabledAt
                ParentVoucher
                TotalQuantity
                AvailableQuantity
                MultiOrderNumberRelationArrayWrapper
                CampaignTypeId
                createdBy
                updatedBy
            }
        }`,
        OrderDiscountCodeCode: gql`
        query orderDiscountCodeCode {
            orderDiscountCodeCode
        }`,
        OrderDiscountCode: gql`
        query orderDiscountCode($code: String) {
            orderDiscountCode(code: $code) {
                id
                IsPercentage
                Value
                Code
                EndOfLiveAt
                RedeemedAt
                BoughtWithOrderNumber
                BoughtWithOrderRowId
                RedeemedWithOrderNumber,
                RedeemedWithOrderRowId
                DiscountCreatedByOrigin
                InternalNote
                IsDisabled
                DisabledAt
                ParentVoucher
                TotalQuantity
                AvailableQuantity
                MultiOrderNumberRelationArrayWrapper
                CampaignTypeId
                createdBy
                updatedBy
            }
        }`,
    },
    Mutations: {
        CreateOrderDiscountCode: gql`
        mutation createOrderDiscountCode($orderDiscountCode: OrderDiscountCodesInput) {
            createOrderDiscountCode(orderDiscountCode: $orderDiscountCode) {
                IsSuccess
                Message
            }
        }`,
        UpdateOrderDiscountCode: gql`
        mutation updateOrderDiscountCode($orderDiscountCode: OrderDiscountCodesInput) {
            updateOrderDiscountCode(orderDiscountCode: $orderDiscountCode) {
                IsSuccess
                Message
            }
        }`,
        SumOrderDiscountCodes: gql`
        mutation sumOrderDiscountCodes($codes: [String], $endOfLiveAt: Date) {
            sumOrderDiscountCodes(codes: $codes, endOfLiveAt: $endOfLiveAt) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
