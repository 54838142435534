<template lang="pug">
.selection-voucher-details
  .container-fluid
    .row(v-if="selectionVoucher")
      .col-12
        h4 Auswahlgutscheindetails
        h6 {{selectionVoucher.Name}} | Verknüpft mit Produkt:&nbsp;
          i.txt-tdays-lighter(v-if="item") {{item.SystemItemId}}
      .col-12.mt-2
        ul.nav.nav-tabs
          li.nav-item
            a.nav-link.rounded-0.active(data-toggle='tab' href='#selectionVoucherData' role='tab' aria-selected='false') Allgemeine Daten
          li.nav-item
            a.nav-link.rounded-0(data-toggle='tab' href='#selectionVoucherItems' role='tab' aria-selected='true') Artikel

        .tab-content.border.p-4
          #selectionVoucherData.tab-pane.fade.show.active
            .container-fluid
              .row
                .col-6
                  .form-group
                    label.form-label Name
                    input.form-control(type="text" v-model="selectionVoucher.Name")
                .col
                  .form-group
                    label.form-label &nbsp;
                    a.button.button-primary.w-100(@click="selectionVoucher.IsActive = !selectionVoucher.IsActive" :class="{ 'button-tpics': !selectionVoucher.IsActive, 'button-tdays': selectionVoucher.IsActive }")
                      | {{ selectionVoucher.IsActive ? 'aktiviert' : 'deaktiviert' }}
                .col
                  .form-check.mt-4
                    b-form-checkbox(switch :checked="selectionVoucher.IsStaticSelectionVoucher" size="lg" v-model="selectionVoucher.IsStaticSelectionVoucher" id="isStaticSelectionVoucher")
                      | statischer Auswahlgutschein?

                .col-6.mt-2
                  .form-group
                    label.form-label Bildpfad
                    input.form-control(type="text" v-model="selectionVoucher.VoucherImage")
                .col-6.mt-2
                  .form-group
                    label.form-label Vorschaubildpfad
                    input.form-control(type="text" v-model="selectionVoucher.RelativeFilePath")

                .col-6.mt-2
                  .form-group
                    label.form-label Headline
                    input.form-control(type="text" v-model="selectionVoucher.Headline")
                .col-6.mt-2
                  .form-group
                    label.form-label Subline
                    input.form-control(type="text" v-model="selectionVoucher.Subline")

                .col-12.mt-2
                  textarea-autosize.form-control(v-model="selectionVoucher.Checklist" placeholder="Checklist")

                .col-12.mt-2
                  textarea-autosize.form-control(v-model="selectionVoucher.Description" placeholder="Beschreibung")

                .col-12.mt-2
                  .float-right
                    button.button.button-primary.button-tdays(@click="updateSelectionVoucher") speichern

          #selectionVoucherItems.tab-pane.fade
            .container-fluid
              .row(v-if="!selectionVoucher.IsStaticSelectionVoucher")
                .col-12
                  .input-group
                    input.form-control(type="text" v-model="itemId" placeholder="Artikel-Id")
                    .input-group-append
                      button.btn-block.button.button-primary.button-tdays.w-100(:disabled="!itemId" :class="{ 'disabled': !itemId }" @click="createSelectionVoucherItem")
                        | Artikel hinzufügen

                .col-12
                  table.table.w-100
                    thead
                      tr
                        th Id
                        th Artikel
                        th #
                    tbody
                      tr(v-for='selectionVoucherItem in selectionVoucherItems' :key='selectionVoucherItem.id')
                        td {{ selectionVoucherItem.id }}
                        td {{ selectionVoucherItem.Item ? selectionVoucherItem.Item.Name : '' }}
                        td
                          button.button.button-primary.button-tpics(@click="deleteSelectionVoucherItem(selectionVoucherItem.id)") entfernen

              .row(v-else)
                .col
                  h5 Zugeordnete Artikel
                .col-6
                  .float-right
                    button.btn-block.button.button-primary.button-tdays.w-100(@click="openCreateSelectionVoucherJsonItemModal")
                      | Artikel hinzufügen
                .col-12
                  table.table.w-100.no-gutters
                    thead
                      tr
                        th Bild
                        th Name
                        th Straße
                        th Postleitzahl
                        th Ort
                        th #
                        th #
                    tbody
                      tr(v-for="jsonItem in selectionVoucher.JsonItems" v-if="selectionVoucher.JsonItems && Array.isArray(selectionVoucher.JsonItems)")
                        td
                          img.img-fluid(:src="jsonItem.ImageUrl")
                        td {{ jsonItem.Name }}
                        td {{ jsonItem.Street }}
                        td {{ jsonItem.ZipCode }}
                        td {{ jsonItem.City }}
                        td
                          button.button.button-primary.button-tdays(@click="openUpdateSelectionVoucherJsonItemModal(jsonItem)")
                            | Artikel bearbeiten
                        td
                          button.button.button-primary.button-tpics(@click="deleteSelectionVoucherJsonItem(jsonItem)")
                            | Artikel löschen

</template>

<script>
import {SelectionVouchers} from "@/graphql/SelectionVouchers.ts";
import {SelectionVoucherItems} from "@/graphql/SelectionVoucherItems.ts";
import EventBus from "@/views/event-bus";
import CreateSelectionVoucherJsonItem from "@/views/modals/selectionVouchers/CreateSelectionVoucherJsonItem";
import UpdateSelectionVoucherJsonItem from "@/views/modals/selectionVouchers/UpdateSelectionVoucherJsonItem";
import {Items} from "@/graphql/Items.ts";

export default {
  name: "SelectionVoucherDetails",
  props: {
    selectionVoucherId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectionVoucher: undefined,
      selectionVoucherItems: [],
      items: [],
      itemId: undefined,
      item: undefined,
    }
  },
  async mounted() {
    try {
      EventBus.$on("updateSelectionVoucher", async () => {
        await this.loadSelectionVoucher();
        await this.loadSelectionVoucherItems();
      });
      await this.loadSelectionVoucher();
      await this.loadSelectionVoucherItems();
    } catch (e) {
      console.log(e);
      await this.$alert(e.message);
    }
  },
  methods: {
    openCreateSelectionVoucherJsonItemModal() {
      try {
        this.$modal.show(
            CreateSelectionVoucherJsonItem,
            {
              selectionVoucherId: this.selectionVoucher.id,
            },
            {height:'auto'}
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openUpdateSelectionVoucherJsonItemModal(selectionVoucherJsonItem) {
      try {
        if (!selectionVoucherJsonItem) {
          return this.$alert("Es wurde kein Item gefunden.");
        }
        const selectionVoucherJsonItemIndex = this.selectionVoucher.JsonItems.indexOf(selectionVoucherJsonItem);
        if (selectionVoucherJsonItemIndex == undefined) {
          return this.$alert("Es wurde kein Artikel-Index gefunden.");
        }
        this.$modal.show(
            UpdateSelectionVoucherJsonItem,
            {
              selectionVoucherId: this.selectionVoucher.id,
              selectionVoucherJsonItem: selectionVoucherJsonItem,
              selectionVoucherJsonItemIndex: selectionVoucherJsonItemIndex,
            },
            {height:'auto'}
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    deleteSelectionVoucherJsonItem(selectionVoucherJsonItem) {
      try {
        if (!selectionVoucherJsonItem) {
          return this.$alert("Kein Artikel zum Löschen gefunden.");
        }
        const selectionVoucherJsonItemIndex = this.selectionVoucher.JsonItems.indexOf(selectionVoucherJsonItem);
        if (selectionVoucherJsonItemIndex == undefined) {
          return this.$alert("Kein Artikel-Index zum Löschen gefunden.");
        }
        this.$confirm("Möchten Sie den Artikel wirklich aus dem Auswahlgutschein entfernen?").then(() => {
          EventBus.$emit("changeLoadingState", true);
          this.$apollo.mutate({
            mutation: SelectionVouchers.Mutations.DeleteSelectionVoucherJsonItem,
            variables: {
              selectionVoucherId: this.selectionVoucher.id,
              selectionVoucherJsonItemIndex: selectionVoucherJsonItemIndex,
            }
          })
              .then(({ data }) => {
                EventBus.$emit("changeLoadingState", false);
                if (!data || !data.deleteSelectionVoucherJsonItem) {
                  return this.$alert("Der Artikel konnte nicht gelöscht werden.");
                }
                if (data.deleteSelectionVoucherJsonItem.Message) {
                  data.deleteSelectionVoucherJsonItem.IsSuccess ? this.$swal(data.deleteSelectionVoucherJsonItem.Message) : this.$alert(data.deleteSelectionVoucherJsonItem.Message);
                }
                if (data.deleteSelectionVoucherJsonItem.IsSuccess) {
                  this.selectionVoucher.JsonItems.splice(selectionVoucherJsonItemIndex, 1);
                }
              })
              .catch((e) => {
                EventBus.$emit("changeLoadingState", false);
                console.log(e);
                this.$alert(e.message);
              });
        })
        .catch(() => {});
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadSelectionVoucher() {
      try {
        const selectionVoucher = await new Promise((resolve) => {
          this.$apollo.query({
            query: SelectionVouchers.Queries.SelectionVoucherById,
            fetchPolicy: "no-cache",
            variables: {
              id: this.selectionVoucherId,
            },
          })
              .then(({data}) => {
                if (!data || !data.selectionVoucherById) {
                  resolve(undefined);
                }
                resolve(data.selectionVoucherById);
              })
        });
        if (!selectionVoucher) {
          return;
        }
        this.selectionVoucher = selectionVoucher;
        await this.loadItem();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadItem() {
      try {
        const item = await new Promise((resolve) => {
          this.$apollo.query({
            query: Items.Queries.Item,
            variables: {
              id: this.selectionVoucher.ItemId,
            }
          })
              .then(({data}) => {
                // eslint-disable-next-line no-debugger
                debugger;
                if (!data || !data.item) {
                  resolve(undefined);
                }
                resolve(data.item);
              });
        });
        // eslint-disable-next-line no-debugger
        debugger;
        if (!item) {
          return;
        }
        this.item = item;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    updateSelectionVoucher() {
      try {
        this.$apollo.mutate({
          mutation: SelectionVouchers.Mutations.UpdateSelectionVoucher,
          variables: {
            selectionVoucher: this.selectionVoucher,
          }
        })
        .then(({ data }) => {
          if (!data || !data.updateSelectionVoucher) {
            return this.$alert("Der Auswahlgutschein konnte nicht gespeichert werden.");
          }
          if (data.updateSelectionVoucher.Message) {
            data.updateSelectionVoucher.IsSuccess ? this.$swal(data.updateSelectionVoucher.Message) : this.$alert(data.updateSelectionVoucher.Message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$alert(e.message);
        });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadSelectionVoucherItems() {
      try {
        const selectionVoucherItems = await new Promise((resolve) => {
          this.$apollo.query({
            query: SelectionVoucherItems.Queries.SelectionVoucherItems,
            fetchPolicy: "no-cache",
            variables: {
              selectionVoucherId: this.selectionVoucherId,
            }
          })
              .then(({data}) => {
                if (!data || !data.selectionVoucherItems) {
                  resolve(undefined);
                }
                resolve(data.selectionVoucherItems);
              })
        });
        if (!selectionVoucherItems) {
          return;
        }
        this.selectionVoucherItems = selectionVoucherItems;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    deleteSelectionVoucherItem(selectionVoucherItemId) {
      try {
        if (!selectionVoucherItemId) {
          return this.$alert("Es wurde keine Id übergeben.");
        }
        this.$apollo.mutate({
          mutation: SelectionVoucherItems.Mutations.DeleteSelectionVoucherItem,
          variables: {
            selectionVoucherItemId: selectionVoucherItemId,
          }
        })
            .then(({data}) => {
              if (!data || !data.deleteSelectionVoucherItem) {
                return this.$alert("Der Auswahlgutschein Artikel konnte nicht gelöscht werden.");
              }
              if (data.deleteSelectionVoucherItem.Message) {
                this.$alert(data.deleteSelectionVoucherItem.Message);
              }
              this.loadSelectionVoucherItems();
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    createSelectionVoucherItem() {
      try {
        if (!this.itemId) {
          return this.$alert("Geben Sie zuerst eine Artikel-Id an.");
        }
        this.$apollo.mutate({
          mutation: SelectionVoucherItems.Mutations.CreateSelectionVoucherItem,
          variables: {
            systemItemId: this.itemId,
            selectionVoucherId: this.selectionVoucherId,
          }
        })
            .then(({data}) => {
              if (!data || !data.createSelectionVoucherItem) {
                return this.$alert("Der Auswahlgutschein Artikel konnte nicht gespeichert werden.");
              }
              if (data.createSelectionVoucherItem.Message) {
                this.$alert(data.createSelectionVoucherItem.Message);
              }
              this.loadSelectionVoucherItems();
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  }
}
</script>

<style scoped>

</style>
