<template lang="pug">
.categories-list
  .container-fluid
    .row
      .col-12
        h4 Kategorien
      .col-12
        input.form-control(type="text" v-model="filter" placeholder="Name..." v-on:keyup.enter="filterCategories")
      .col-12
        table.table.w-100
          thead
            tr
              th Id
              th Name
              th Level
              th #
          tbody
            tr(v-for='category in categories' :key='category.id')
              td {{ category.id }}
              td {{ category.Name }}
              td {{ category.CategoryLevelDepth }}
              td
                router-link.button.button-primary.button-tdays(:to="{ name: 'CategoryDetails', params: { categoryId: category.id }, }")
                  | zur Kategorie
      .offset-4.col-4
        .text-center
          a.h5(:class="{ 'disabled': categories.length == categoriesCount }" @click="loadMore")
            | mehr laden

      .col-4
        .float-right
          button.button.button-primary.button-tdays(@click="generateCategoryNavbar")
            | Navigation generieren

</template>

<script>
import {Categories} from "@/graphql/Categories.ts";

export default {
  name: "CategoriesList",
  data() {
    return {
      categories: [],
      categoriesCount: 0,

      filter: "",
      offset: 0,
      limit: 15,
    }
  },
  mounted() {
    this.loadCategoriesCount();
    this.loadCategories();
  },
  methods: {
    loadCategoriesCount() {
      try {
        this.$apollo.query({
          query: Categories.Queries.CategoriesCount,
          variables: {
            filter: this.filter,
          }
        })
            .then(({data}) => {
              if (!data || !data.categoriesCount) {
                return;
              }
              this.categoriesCount = data.categoriesCount;
            })
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    loadCategories() {
      try {
        this.$apollo.query({
          query: Categories.Queries.Categories,
          variables: {
            filter: this.filter,
            offset: this.offset,
            limit: this.limit,
          }
        })
            .then(({data}) => {
              if (!data || !data.categories) {
                return;
              }
              this.categories = this.categories.concat(data.categories);
            })
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    filterCategories() {
      try {
        this.offset = 0;
        this.categories = [];
        this.loadCategoriesCount();
        this.loadCategories();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadMore() {
      try {
        if (this.categories.length == this.categoriesCount) {
          return;
        }
        this.offset += this.limit;
        this.loadCategories();
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    generateCategoryNavbar() {
      try {
        this.$apollo.mutate({
          mutation: Categories.Mutations.GenerateCategoryNavbar
        })
            .then(({data}) => {
              if (!data || !data.generateCategoryNavbar) {
                return this.$alert("Die Navigation konnte nicht generiert werden.");
              }
              if (data.generateCategoryNavbar.Message) {
                this.$alert(data.generateCategoryNavbar.Message);
              }
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
}
</script>
