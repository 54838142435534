<template lang="pug">
  .item-details(v-if="item" )
    .container-fluid
      .row
        .col-12
          h4 Artikeldetails
        .col-4
          .card
            .card-header
              .card-title
                b Allgemein
            .card-body
              ul.list-group.list-group-flush
                li.list-group-item
                  b Produkt-Nr:&nbsp;
                  | {{this.item.SystemItemId}}
                li.list-group-item
                  b Moloss-Id:&nbsp;
                  | {{this.item.id}}
                li.list-group-item
                  b Name 1:&nbsp;
                  | {{this.item.Name1}}
        .col-4
          .card
            .card-header
              .card-title
                b Texte
            .card-body
              .row
                .col-12
                  input.form-control(type="text" v-model="this.item.CustomAttributes.Name2" placeholder="Slider Headline")
                .col-12.mt-2
                  input.form-control(type="text" v-model="this.item.CustomAttributes.Custom_Product_Headline02" placeholder="Slider Subline")
        .col-4
          .card
            .card-header
              .card-title
                b Marketing | Webshop
            .card-body
              b-form-checkbox(switch :checked="item.IsHighlightOfTheWeekOnStartPage" size="lg" v-model="item.IsHighlightOfTheWeekOnStartPage" id="IsHighlightOfTheWeekOnStartPage")
                | Ist HDW auf der Startseite?
              ul.list-group.list-group-flush
                li.list-group-item
                  b Live-Shopping Start:&nbsp;
                  | {{this.$moment(this.item.AdvertisedFrom).format('DD-MM-YYYY HH:mm')}}
                li.list-group-item
                  b Live-Shopping Ende:&nbsp;
                  | {{this.$moment(this.item.AdvertisedTill).format('DD-MM-YYYY HH:mm')}}
                li.list-group-item
                  b Aktueller Verkaufspreis:&nbsp;
                  | {{this.item.CurrentPrice}} €
                li.list-group-item
                  b Deal Preis:&nbsp;
                  | {{this.item.LiveShoppingPrice}} €
                li.list-group-item
                  b Preis:&nbsp;
                  | {{this.item.DiscountedPrice}} €
                li.list-group-item
                  b Wert:&nbsp;
                  | {{this.item.RegularPrice}} €
                li.list-group-item
                  b Person:&nbsp;
                  | {{this.item.Persons}}
                li.list-group-item
                  b Verpflegung:&nbsp;
                  | {{this.item.Catering}}
                li.list-group-item
                  b Nächte:&nbsp;
                  | {{this.item.Nights}}
</template>

<script>
import {Items} from "@/graphql/Items.ts";

export default {
  name: "ItemDetails",
  props: {
    itemId: {
      type: String,
      required: true,
    },
    hostId: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      item: null,
    }
  },
  mounted() {
    this.loadItem();
  },
  methods: {
    loadItem() {
      try {
        this.$apollo.query({
          query: Items.Queries.Item,
          variables: {
            id: this.itemId
          }
        }).then(({data}) => {
          if (!data || !data.item) {
            return;
          }
          this.item = data.item;
        })
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    saveItem() {
      this.$alert("not implemented...");
    },
  }
}
</script>

<style scoped>

</style>
