<template lang="pug">
  .home
    .container-fluid
      .row
        .col-12
          ul.nav-tabs.nav
            li.nav-item
              a.nav-link.rounded-0.active(data-toggle="tab" href="#futureHighlights" role="tab" aria-selected="true") Kommende HDW
            li.nav-item
              a.nav-link.rounded-0(data-toggle="tab" href="#currentHighlight" role="tab" aria-selected="false") Aktuelles HDW
          .tab-content
            #futureHighlights.tab-pane.fade.show.active
              template(v-for="item in items")
                .container
                  ItemHighlight(:item="item")
                  hr
            #currentHighlight.tab-pane.fade
              .container
                ItemHighlight(:item="currentHighlight" v-if="currentHighlight")
</template>

<script>
import {Items} from "@/graphql/Items.ts";
import {Utils} from "@/graphql/Utils.ts";
import ItemHighlight from "@/views/home/partials/ItemHighlight";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {ItemHighlight},
  data() {
    return {
      items: [],
      currentHighlight: undefined
    }
  },
  mounted() {
    try {
      this.loadHighlightItems();
      this.loadCurrentHighlight();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    loadCurrentHighlight(){
      try {
        this.$apollo.query({
          query: Items.Queries.CurrentHighlightItem,
          fetchPolicy: "no-cache",
        })
            .then(({data}) => {
              if (!data || !data.highlightItem) {
                return;
              }
              this.currentHighlight = data.highlightItem;
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadHighlightItems() {
      try {
        this.$apollo.query({
          query: Items.Queries.FutureHighlightItems,
          fetchPolicy: "no-cache",
        })
            .then(({data}) => {
              if (!data || !data.futureHighlightItems) {
                return;
              }
              this.items = data.futureHighlightItems;
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
  apollo: {
    colorPalette: {
      query: Utils.Queries.ColorPalette,
      variables() {
        return {
          imageSource: this.firstImage ? (this.firstImage + "large.jpg") : undefined,
        }
      }
    }
  }
}
</script>

<style>
.color-input.user .box {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 20%;
  overflow: hidden;
  background: #fbfbfb;
  transition: all 0.2s, background-color 0.05s
}
</style>
