<template lang="pug">
.send-marketing-promo-contest-prize-winner-email-modal.p-4
  .container-fluid
    .row
      .col-12
        h5 Gewinner E-Mail versenden

      .col-12.mt-2
        .form-group
          label.form-label E-Mail*
          input.form-control(v-model="email")

      .col-6.mt-2
        button.button.button-primary.button-tdays.w-100(type='button' @click="sendMarketingPromoContestPrizeWinnerEmail")
          | senden
      .col-6.mt-2
        button.button.button-primary.button-tpics.w-100(type='button' @click="$emit('close')")
          | schließen

</template>

<script>
import EventBus from "@/views/event-bus";
import {MarketingPromoContestPrizes} from "@/graphql/MarketingPromoContestPrizes.ts";

export default {
  name: "SendMarketingPromoContestPrizeWinnerEmailModal",
  props: {
    marketingPromoContestPrizeId: {
      type: String,
      required: true,
    },
    mailTemplate:{
      type:String,
      default:"xmas-winner"
    }
  },
  data() {
    return {
      email: "",
    }
  },
  methods: {
    sendMarketingPromoContestPrizeWinnerEmail() {
      try {
        if (!this.email) {
          return this.$alert("Bitte vergeben Sie eine gültige E-Mail Adresse.");
        }

        EventBus.$emit("changeLoadingState", true);
        this.$apollo.mutate({
          mutation: MarketingPromoContestPrizes.Mutations.SendMarketingPromoContestPrizeWinnerEmail,
          fetchPolicy: "no-cache",
          variables: {
            marketingPromoContestPrizeId: this.marketingPromoContestPrizeId,
            email: this.email,
            template:this.mailTemplate
          }
        })
            .then(({data}) => {
              EventBus.$emit("changeLoadingState", false);
              if (!data || !data.sendMarketingPromoContestPrizeWinnerEmail) {
                return this.$alert("Es konnte keine E-Mail verschickt werden.");
              }
              if (data.sendMarketingPromoContestPrizeWinnerEmail.Message) {
                this.$alert(data.sendMarketingPromoContestPrizeWinnerEmail.Message);
              }
              if (data.sendMarketingPromoContestPrizeWinnerEmail.IsSuccess) {
                this.$emit("close");
              }
            })
            .catch((e) => {
              EventBus.$emit("changeLoadingState", false);
              console.log(e);
              this.$alert(e.message);
            })
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
}
</script>

<style scoped>
</style>
