import gql from "graphql-tag";

export const Categories = {
    Queries: {
        Categories: gql`
        query categories($filter: String, $offset: Int, $limit: Int) {
            categories(filter: $filter, offset: $offset, limit: $limit) {
                id
                Name
                WebTemplateId
                CategoryLevelDepth
            }
        }`,
        CategoriesCount: gql`
        query categoriesCount($filter: String) {
            categoriesCount(filter: $filter)
        }`,
        HostCategories: gql`
        query hostCategories($hostId: ID) {
            hostCategories(hostId: $hostId) {
                id
                Name
            }
        } `,
    },
    Mutations: {
        GenerateCategoryNavbar: gql`
        mutation generateCategoryNavbar {
            generateCategoryNavbar {
                IsSuccess
                Message
            }
        }`
    }
}
