<template lang="pug">
.items-list
  .container-fluid
    .row
      .col-12
        table.table.w-100
          thead
            tr
              th Id
              th Name
              th Aktueller Preis
              th #
          tbody
            tr(v-for='item in this.items' :key='item.id')
              td {{ item.SystemItemId }} ({{ item.id }})
              td {{ item.CustomAttributes.Name1 }}
              td {{ item.CurrentPrice | formatCurrency }}
              td
                router-link.button.button-primary.button-tdays(:to="{ name: 'ItemDetails', params: { hostId: item.CustomAttributes.Custom_HostId, itemId: item.id }, }")
                  | zum Artikel
</template>

<script>

export default {
  name: "ItemsList",
  props: {
    items: {
      type: Array,
      required: true,
    },
    hostId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {

  },
}
</script>
