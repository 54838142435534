import { render, staticRenderFns } from "./SendMarketingPromoContestFriendSharingEmailModal.vue?vue&type=template&id=1d0896a3&scoped=true&lang=pug"
import script from "./SendMarketingPromoContestFriendSharingEmailModal.vue?vue&type=script&lang=js"
export * from "./SendMarketingPromoContestFriendSharingEmailModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d0896a3",
  null
  
)

export default component.exports