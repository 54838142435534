<template lang="pug">
  .row
    .col-12
      h5 {{title}}

    .col-12.mt-2
      input.form-control(placeholder="Name" v-model="formData.name" autofocus)
    .col-12.mt-2
      input.form-control(placeholder="Anschrift" v-model="formData.street")
    .col-6.mt-2
      input.form-control(placeholder="Postleitzahl" v-model="formData.zipCode")
    .col-6.mt-2
      input.form-control(placeholder="Stadt" v-model="formData.city")
    .col-12.mt-2
      input.form-control(placeholder="Bild-Url" v-model="formData.imageUrl")

    .col-4.mt-2
      input.form-control(placeholder="Breitengrad" v-model="latitudeValue")
    .col-4.mt-2
      input.form-control(placeholder="Längengrad" v-model="longitudeValue")
    .col-4.mt-2
      button.button.button-primary.button-tdays.w-100(type='button' @click="findLocationForAddress") Auto
    .col-6.mt-2
      button.button.button-primary.button-tdays.w-100(type='button' @click="save")
        | speichern
    .col-6.mt-2
      button.button.button-primary.button-tpics.w-100(type='button' @click="$emit('close')")
        | schließen
</template>

<script>
import Geocoder from "@touridev/google-geocoder";
//import EventBus from "@/views/event-bus";
export const bindItem = (voucherItem) => {
  const lowerCaseFirstLetter = (str) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  const binding = {};
  Object.keys(voucherItem).forEach((key) => binding[lowerCaseFirstLetter(key)] = voucherItem[key]);
  return binding;
}

const unwrapItem = (item) => {
  const upperCaseFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const voucherItem = {};
  Object.keys(item).forEach((key) => voucherItem[upperCaseFirstLetter(key)] = item[key]);
  return voucherItem;
}

export default {
  name: "JsonItemForm",
  props:{
    title:String,
    name:String,
    street:String,
    zipCode:String,
    city:String,
    imageUrl:String,
    latitude:String,
    longitude:String
  },
  setup(props){
    const propData = {};
    Object.keys(props).forEach((key) => {
      if(['title'].includes(key)) return;
      propData[key] = props[key]
    });

    const geocoder = new Geocoder({key:'AIzaSyBWI5arkEJpEs2qLS6QEcFLDPOg29g0nbM'})
    return {propData,unwrapItem,geocoder}
  },
  data(){
    return {
      formData:{},
      latitudeValue:undefined,
      longitudeValue:undefined,
    }
  },
  methods:{
    async searchLocation(term){
      const results = await this.geocoder.search(term);
      if(results.length === 0 || !results[0]?.geometry?.location) return;
      return results[0].geometry.location;
    },
    async findLocationForAddress(){
      try {
        //EventBus.$emit("changeLoadingState", true);
        if(!this.formData.street || !this.formData.zipCode || !this.formData.city){
          await this.$alert("Bitte zuerst die erforderlichen Addressdaten eingeben.");
          return;
        }
        const fullAddress = `${this.formData.street || ''} ${this.formData.zipCode || ''} ${this.formData.city || ''}`.trim();
        const location = await this.searchLocation(fullAddress);
        if(!location) {
          await this.$alert("Es konnten keine Geodaten für die angegebene Addresse gefunden werden.");
          return;
        }
        this.latitudeValue = location?.lat.toFixed(5);
        this.longitudeValue = location?.lng.toFixed(5);
      } catch (e) {
        await this.$alert(e?.message || e);
      } finally {
        //EventBus.$emit("changeLoadingState", false);
      }
    },
    changePropertyValue(name,value){
      this.formData[name] = value;
    },
    validateImageUrl(url){
      return (typeof url === "string") && (url.match(/(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif))$/gmi) != null);
    },
    validate(item){
      if (!item.name) {
        this.$alert("Bitte vergeben Sie zuerst einen Namen.");
        return false;
      }
      if (!item.street) {
        this.$alert("Bitte vergeben Sie zuerst eine Anschrift.");
        return false;
      }
      if (!item.zipCode) {
        this.$alert("Bitte vergeben Sie zuerst eine Postleitzahl.");
        return false;
      }
      if (!item.city) {
        this.$alert("Bitte vergeben Sie zuerst eine Stadt.");
        return false;
      }
      if (!item.imageUrl) {
        this.$alert("Bitte vergeben Sie zuerst eine Bild-Url.");
        return false;
      }
      if(!this.validateImageUrl(item.imageUrl)) {
        this.$alert("Die angegebene Bild-Url ist Fehlerhaft.");
        return false;
      }

      return true;
    },
    save(){
      const data = {...this.formData,latitude:this.latitudeValue,longitude:this.longitudeValue};
      if(this.validate(data)) this.$emit('save',unwrapItem(data))
    }
  },
  beforeMount(){
    Object.keys(this.propData).forEach((name) => {
      const value = this.propData[name];
      this.changePropertyValue(name,value)
    });
    this.latitudeValue = this.latitude;
    this.longitudeValue = this.longitude;
  }
}
</script>

<style scoped>

</style>
