import gql from "graphql-tag";

export const MarketingPromoContests = {
    Queries: {
        MarketingPromoContests: gql`
        query marketingPromoContests($offset: Int, $limit: Int) {
            marketingPromoContests(offset: $offset, limit: $limit) {
                id
                Name
                PrizeEnabledFrom
                PrizeEnabledTill
                RegistrationFrom
                RegistrationTill
                Description
                createdAt
                updatedAt
            }
        }`,
        MarketingPromoContestsCount: gql`
        query marketingPromoContestsCount {
            marketingPromoContestsCount
        }`,
        MarketingPromoContest: gql`
        query marketingPromoContest($id: ID!) {
            marketingPromoContest(id: $id) {
                id
                Name
                PrizeEnabledFrom
                PrizeEnabledTill
                RegistrationFrom
                RegistrationTill
                Description
                createdAt
                updatedAt
            }
        }`,
    },
    Mutations: {
        CreateMarketingPromoContest: gql`
        mutation createMarketingPromoContest($marketingPromoContest: MarketingPromoContestsInput) {
            createMarketingPromoContest(marketingPromoContest: $marketingPromoContest) {
                IsSuccess
                Message
            }
        }`,
        UpdateMarketingPromoContest: gql`
        mutation updateMarketingPromoContest($marketingPromoContest: MarketingPromoContestsInput) {
            updateMarketingPromoContest(marketingPromoContest: $marketingPromoContest) {
                IsSuccess
                Message
            }
        }`,
    }
};
