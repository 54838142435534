import Vue from "vue";

import "vue-search-select/dist/VueSearchSelect.css";

const moment = require("moment");
import { create, all } from 'mathjs'
const config = {};
const math = create(all, config)
import Config from "@/config/config";
const cfg = Config();

Vue.filter(
    "formatCurrency",
    (value) => {
        if (!value) {
            return "0,00 €";
        }
        return `${parseFloat(value).toFixed(2).toString().replace(".", ",")} €`;
    },
);
Vue.filter(
    "formatDate",
    (value) => {
        if (!value) {
            return "";
        }
        return moment(String(value)).format("DD.MM.YYYY");
    },
);
Vue.filter(
    "formatDateTime",
    (value) => {
        if (!value) {
            return "";
        }
        return moment(String(value)).format("DD.MM.YYYY HH:mm");
    },
);
Vue.filter(
    "formatInputDateTime",
    (value) => {
        if (!value) {
            return undefined;
        }
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
    },
);
Vue.filter(
    "formatPercentage",
    (value) => {
        if (!value) {
            return "0,00 %";
        }
        return `${parseFloat(value).toFixed(2).toString().replace(".", ",")} %`;
    }
);
Vue.filter(
    "calculatePercent",
    (percentValue, allValue) => {
        if (!percentValue){
            return "0,00%";
        }
        if (!allValue){
            return "0,00%";
        }
        const percent = (percentValue * 100) / allValue;
        return `${math.round(percent, 2).toString().replace(".", ",")} %`;
    }
);

import VModal from "vue-js-modal";
Vue.use(VModal);

import TextareaAutosize from "vue-textarea-autosize";
Vue.use(TextareaAutosize);

import VueSession from "vue-session";
Vue.use(VueSession, {
    persist: true,
});

import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert, {
    customClass: {
        confirmButton: "button button-primary button-tdays",
        cancelButton: "button button-primary button-tpics ml-3",
        popup: "alert-popup",
    },
    cancelButtonText: "Abbrechen",
    buttonsStyling: false,
});

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2, {
    customClass: {
        confirmButton: "button button-primary button-tdays",
        cancelButton: "button button-primary button-tpics ml-3",
        popup: "alert-popup",
    },
    timer: 1500,
    cancelButtonText: "Abbrechen",
    buttonsStyling: false,
});

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import {createHttpLink} from "apollo-link-http";
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: cfg.graphQl.uri,
});
import { ApolloLink, concat } from 'apollo-link';

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const $session = Vue.prototype.$session;
    if(!$session.exists()) $session.start();
    const authorization = $session.has('token') ? `Bearer ${$session.get('token')}` : null;
    operation.setContext({
        headers: {
            authorization,
        }
    });
    return forward(operation);
})

import router from "./router";
import { onError } from 'apollo-link-error';
const logoutLink = onError(async ({ networkError }) => {
    const $session = Vue.prototype.$session;
    const $alert = Vue.prototype.$alert;
    if(!$session.exists()) $session.start();
    if ([401,403].includes(networkError.statusCode)){
        $session.remove("token");
        await $alert("Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.")
        await router.replace('/');
    }
})


// Cache implementation
import {InMemoryCache} from "apollo-cache-inmemory";
const cache = new InMemoryCache({
    addTypename: false
});

import ApolloClient from "apollo-client";
const apolloClient = new ApolloClient({
    link: logoutLink.concat(concat(authMiddleware, httpLink)),
    cache,
});

import VueApollo from "vue-apollo";

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});
Vue.use(VueApollo);

import App from "./views/App.vue";

Vue.prototype.$math = math;
Vue.prototype.$touriConfig = cfg;
Vue.prototype.$moment = moment;

new Vue({
    router,
    apolloProvider: apolloProvider,
    render: (h) => h(App),
}).$mount("#app");

