import { render, staticRenderFns } from "./ItemHighlight.vue?vue&type=template&id=20aa21cb&scoped=true&lang=pug"
import script from "./ItemHighlight.vue?vue&type=script&lang=js"
export * from "./ItemHighlight.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20aa21cb",
  null
  
)

export default component.exports