<template lang="pug">
.create-marketing-promo-contest-prize-modal.p-4
  .container-fluid
    .row
      template(v-if="isDiscountOnlyPrize")
        .col-12
          h5 Neuer Gutscheinpreis

        .col-6.mt-2
          label.form-label Wert*
          input.form-control(type="number" v-model="value")
        .col-6.mt-2
          label.form-label Anzahl*
          input.form-control(type="number" v-model="quantity")

      template(v-else)
        .col-12
          h5 Neuer Artikelpreis

        .col-12.mt-2(v-if="item")
          b {{ item.CustomAttributes.Name1 }}
          p {{ item.CustomAttributes.Custom_Product_Arrangement }}

        .col-12.mt-2
          .form-group
            label.form-label Item-Id*
            input.form-control(v-model="itemId" @change="loadItem")

        .col-12
          .form-group
            label.form-label Headline*
            input.form-control(v-model="htmlHeadline")

        .col-12.mt-2
          .form-group
            label.form-label Subline*
            input.form-control(v-model="htmlSubline")

        .col-12.mt-2
          .form-group
            label.form-label Beschreibung*
            textarea-autosize.form-control(v-model="htmlDescription")

        .col-12.mt-2
          .form-group
            label.form-label Bild-Url*
            input.form-control(v-model="imageUrl")

      .col-12.mt-2
        .form-group
          label.form-label Preis ziehen am*
          input.form-control(type="datetime-local" :value="drawPrizeAt | formatInputDateTime" @change="drawPrizeAt = $event.target.value;")

      .col-6.mt-2
        button.button.button-primary.button-tdays.w-100(type='button' @click="createMarketingPromoContestPrize")
          | speichern
      .col-6.mt-2
        button.button.button-primary.button-tpics.w-100(type='button' @click="$emit('close')")
          | schließen

</template>

<script>
import EventBus from "@/views/event-bus";
import {MarketingPromoContestPrizes} from "@/graphql/MarketingPromoContestPrizes.ts";
import {Items} from "@/graphql/Items.ts";

export default {
  name: "CreateMarketingPromoContestModal",
  props: {
    marketingPromoContestId: {
      type: String,
      required: true,
    },
    isDiscountOnlyPrize: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      item: undefined,
      itemId: "",
      htmlHeadline: "",
      htmlSubline: "",
      htmlDescription: "",
      imageUrl: "",

      value: undefined,
      quantity: undefined,

      drawPrizeAt: undefined,
    }
  },
  methods: {
    createMarketingPromoContestPrize() {
      try {
        let marketingPromoContestPrize;
        if (this.isDiscountOnlyPrize) {
          if (!this.value) {
            return this.$alert("Bitte vergeben Sie einen Wert.");
          }
          if (!this.quantity) {
            return this.$alert("Bitte vegeben Sie eine Anzahl.");
          }
          marketingPromoContestPrize = {
            Value: parseFloat(this.value),
            Quantity: parseInt(this.quantity),
          };
        } else {
          if (!this.item) {
            return this.$alert("Bitte vergeben Sie eine gültige Artikel-Id.");
          }
          if (!this.itemId) {
            return this.$alert("Bitte vergeben Sie eine Artikel-Id.");
          }
          if (!this.htmlHeadline) {
            return this.$alert("Bitte vergeben Sie eine Headline.");
          }
          if (!this.htmlSubline) {
            return this.$alert("Bitte vergeben Sie eine Subline.");
          }
          if (!this.htmlDescription) {
            return this.$alert("Bitte vergeben Sie eine Beschreibung.");
          }
          marketingPromoContestPrize = {
            ItemId: this.item.id,
            HtmlHeadline: this.htmlHeadline,
            HtmlSubline: this.htmlSubline,
            HtmlDescription: this.htmlDescription,
            ImageUrl: this.imageUrl,
          };
        }
        if (!this.drawPrizeAt) {
          return this.$alert("Bitte vergeben Sie einen Auslosezeitpunkt.");
        }

        marketingPromoContestPrize.IsDiscountOnlyPrize = this.isDiscountOnlyPrize;
        marketingPromoContestPrize.MarketingPromoContestId = this.marketingPromoContestId;
        marketingPromoContestPrize.DrawPrizeAt = this.drawPrizeAt;
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.mutate({
          mutation: MarketingPromoContestPrizes.Mutations.CreateMarketingPromoContestPrize,
          variables: {
            marketingPromoContestPrize: marketingPromoContestPrize,
          }
        })
        .then(({ data }) => {
          EventBus.$emit("changeLoadingState", false);
          if (!data || !data.createMarketingPromoContestPrize) {
            return this.$alert("Es konnte kein Preis erstellt werden.");
          }
          if (data.createMarketingPromoContestPrize.Message) {
            this.$alert(data.createMarketingPromoContestPrize.Message);
          }
          if (data.createMarketingPromoContestPrize.IsSuccess) {
            EventBus.$emit("updateMarketingPromoContestPrizesList");
            this.$emit("close");
          }
        })
        .catch((e) => {
          EventBus.$emit("changeLoadingState", false);
          console.log(e);
          this.$alert(e.message);
        })
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadItem() {
      try {
        if (!this.itemId) {
          return;
        }
        this.$apollo.query({
          query: Items.Queries.SystemItem,
          variables: {
            systemItemId: this.itemId,
          }
        })
        .then(({ data }) => {
          if (!data || !data.systemItem) {
            return this.$alert("Der Artikel konnte nicht geladen werden.");
          }
          this.item = data.systemItem;
        })
        .catch((e) => {
          console.log(e);
          this.$alert(e.message);
        });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  },
}
</script>

<style scoped>
</style>
