<template lang="pug">
.order-voucher-codes-list
  .container-fluid
    .row
      .col-12
        h4 Auswahlgutscheine
      .col-12
        input.form-control(type="text" v-model="filter" placeholder="Gutscheincode-Id oder Code..." v-on:keyup.enter="filterOrderVoucherCodes")
      .col-12
        table.table.w-100
          thead
            tr
              th Id
              th Code
              th Art
              th Gültigkeitsdatum
              th Eingelöst am
              th #
          tbody
            tr(v-for='orderVoucherCode in orderVoucherCodes' :key='orderVoucherCode.id')
              td {{ orderVoucherCode.id }}
              td {{ orderVoucherCode.Code }}
              td {{ orderVoucherCode.SelectionVoucher ? orderVoucherCode.SelectionVoucher.Name : '' }}
              td {{ orderVoucherCode.EndOfLiveAt | formatDateTime }}
              td {{ orderVoucherCode.RedeemedAt | formatDateTime }}
              td
                router-link.button.button-primary.button-tdays(:to="{ name: 'OrderVoucherCodeDetails', params: { orderVoucherCodeId: orderVoucherCode.id }, }")
                  | zum Auswahlgutschein
      .col-4.offset-4
        .text-center
          a.h5(:class="{ 'disabled': orderVoucherCodes.length == orderVoucherCodesCount }" @click="loadMoreOrderVoucherCodes")
            | mehr laden
      .col-4
        .float-right
          button.button.button-primary.button-tdays(@click="openCreateOrderDiscountCodeModal") neuer Auswahlgutschein

</template>

<script>
import {OrderVoucherCodes} from "@/graphql/OrderVoucherCodes.ts";
import CreateOrderVoucherCodeModal from "@/views/modals/orderVoucherCodes/CreateOrderVoucherCodeModal";
import EventBus from "@/views/event-bus";

export default {
  name: "OrderVoucherCodesList",
  data() {
    return {
      orderVoucherCodes: [],
      orderVoucherCodesCount: 0,
      filter: "",
      offset: 0,
      limit: 15,
    };
  },
  mounted() {
    EventBus.$on(
        "updateOrderDiscountCodes",
        function () {
          this.filterOrderDiscountCodes();
        }.bind(this)
    );
    this.loadOrderVoucherCodes();
    this.loadOrderVoucherCodesCount();
  },
  methods: {
    openCreateOrderDiscountCodeModal() {
      try {
        this.$modal.show(
            CreateOrderVoucherCodeModal,
            {},
            {
              height: "auto",
            },
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadOrderVoucherCodes() {
      this.$apollo.query({
        query: OrderVoucherCodes.Queries.OrderVoucherCodes,
        variables: {
          filter: this.filter,
          offset: this.offset,
          limit: this.limit,
        }
      })
          .then(({data}) => {
            if (!data || !data.orderVoucherCodes) {
              return this.$alert("Es konnten keine Auswahlgutscheine geladen werden.");
            }
            this.orderVoucherCodes = this.orderVoucherCodes.concat(data.orderVoucherCodes);
          });
    },
    loadOrderVoucherCodesCount() {
      this.$apollo.query({
        query: OrderVoucherCodes.Queries.OrderVoucherCodesCount,
        variables: {
          filter: this.filter,
        }
      })
          .then(({data}) => {
            if (!data || !data.orderVoucherCodesCount) {
              return;
            }
            this.orderVoucherCodesCount = data.orderVoucherCodesCount;
          });
    },
    loadMoreOrderVoucherCodes() {
      try {
        if (this.orderVoucherCodes.length == this.orderVoucherCodesCount) {
          return;
        }
        this.offset += this.limit;
        this.loadOrderVoucherCodes();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    filterOrderVoucherCodes() {
      try {
        this.orderVoucherCodes = [];
        this.offset = 0;
        this.loadOrderVoucherCodes();
        this.loadOrderVoucherCodesCount();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
}
</script>

<style scoped>

</style>
