<template lang="pug">
.marketing-promo-contests-list
  .container-fluid
    .row
      .col-12
        h4 Gewinnspiele
      .col-12
        table.table.w-100
          thead
            tr
              th Id
              th Name
              th Registration
              th Preisgültigkeit
              th #
          tbody
            tr(v-for='marketingPromoContest in marketingPromoContests' :key='marketingPromoContest.id')
              td {{ marketingPromoContest.id }}
              td {{ marketingPromoContest.Name }}
              td {{ marketingPromoContest.RegistrationFrom | formatDate }} - {{ marketingPromoContest.RegistrationTill | formatDate }}
              td {{ marketingPromoContest.PrizeEnabledFrom | formatDate }} - {{ marketingPromoContest.PrizeEnabledTill | formatDate }}
              td
                router-link.button.button-primary.button-tdays(:to="{ name: 'MarketingPromoContestDetails', params: { marketingPromoContestId: marketingPromoContest.id }, }")
                  | zum Gewinnspiel
      .col-4.offset-4
        .text-center
          a.h5(:class="{ 'disabled': marketingPromoContests.length == marketingPromoContestsCount }" @click="loadMoreMarketingPromoContests")
            | mehr laden
      .col-4
        .float-right
          button.button.button-primary.button-tdays(@click="openCreateMarketingPromoContestModal") neues Gewinnspiel

</template>

<script>
import {MarketingPromoContests} from "@/graphql/MarketingPromoContests.ts";
import CreateMarketingPromoContestModal
  from "@/views/modals/marketingPromoContests/CreateMarketingPromoContestModal";
import EventBus from "@/views/event-bus";

export default {
  name: "PromoContestsList",
  data() {
    return {
      marketingPromoContests: [],
      marketingPromoContestsCount: 0,

      offset: 0,
      limit: 10,
    };
  },
  mounted() {
    try {
      EventBus.$on(
          "updateMarketingPromoContestsList",
          function () {
            this.marketingPromoContests = [];
            this.marketingPromoContestsCount = 0;
            this.offset = 0;
            this.loadMarketingPromoContests();
            this.loadMarketingPromoContestsCount();
          }.bind(this)
      );
      this.loadMarketingPromoContests();
      this.loadMarketingPromoContestsCount();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    loadMarketingPromoContests() {
      try {
        EventBus.$emit("changeLoadingState", true);
        this.$apollo.query({
          query: MarketingPromoContests.Queries.MarketingPromoContests,
          variables: {
            offset: this.offset,
            limit: this.limit,
          },
        })
        .then(({ data }) => {
          EventBus.$emit("changeLoadingState", false);
          if (!data || !data.marketingPromoContests) {
            this.$alert("Es konnten keine Gewinnspiele geladen werden.");
          }
          this.marketingPromoContests = this.marketingPromoContests.concat(data.marketingPromoContests);
        })
        .catch((e) => {
          EventBus.$emit("changeLoadingState", false);
          console.log(e);
          this.$alert(e.message);
        });
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadMarketingPromoContestsCount() {
      try {
        this.$apollo.query({
          query: MarketingPromoContests.Queries.MarketingPromoContestsCount,
        })
            .then(({ data }) => {
              if (!data || !data.marketingPromoContestsCount) {
                return;
              }
              this.marketingPromoContestsCount = data.marketingPromoContestsCount;
            })
            .catch((e) => {
              console.log(e);
              this.$alert(e.message);
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadMoreMarketingPromoContests() {
      try {
        if (this.marketingPromoContests.length == this.marketingPromoContestsCount) {
          return;
        }
        this.offset += this.limit;
        this.loadMarketingPromoContests();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openCreateMarketingPromoContestModal() {
      try {
        this.$modal.show(
            CreateMarketingPromoContestModal,
            {},
            {
              height: "auto",
            },
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped>

</style>
