<template lang="pug">
.order-discount-codes-list
  .container-fluid
    .row
      .col-12
        h4 Wertgutscheine
      .col-12
        input.form-control(type="text" v-model="filter" placeholder="Gutscheincode-Id oder Code..." v-on:keyup.enter="filterOrderDiscountCodes")
      .col-12
        table.table.w-100
          thead
            tr
              th Id
              th Code
              th Wert
              th Gültigkeitsdatum
              th Eingelöst am
              th #
          tbody
            tr(v-for='orderDiscountCode in orderDiscountCodes' :key='orderDiscountCode.id')
              td {{ orderDiscountCode.id }}
              td {{ orderDiscountCode.Code }}
              td
                template(v-if="orderDiscountCode.IsPercentage")
                  | {{ orderDiscountCode.Value }}%
                template(v-else)
                  | {{ orderDiscountCode.Value | formatCurrency }}
              td {{ orderDiscountCode.EndOfLiveAt | formatDateTime }}
              td {{ orderDiscountCode.RedeemedAt | formatDateTime }}
              td
                router-link.button.button-primary.button-tdays(:to="{ name: 'OrderDiscountCodeDetails', params: { orderDiscountCodeId: orderDiscountCode.id }, }")
                  | zum Wertgutschein
      .col-4.offset-4
        .text-center
          a.h5(:class="{ 'disabled': orderDiscountCodes.length == orderDiscountCodesCount }" @click="loadMoreOrderDiscountCodes")
            | mehr laden
      .col-4
        .float-right
          button.button.button-primary.button-tdays.mr-2(@click="openSumOrderDiscountCodesModal") Wertgutscheine zusammenfassen
          button.button.button-primary.button-tdays(@click="openCreateOrderDiscountCodeModal") neuer Wertgutschein

</template>

<script>
import {OrderDiscountCodes} from "@/graphql/OrderDiscountCodes.ts";
import CreateOrderDiscountCodeModal from "@/views/modals/orderDiscountCodes/CreateOrderDiscountCodeModal";
import EventBus from "@/views/event-bus";
import SumOrderDiscountCodesModal from "@/views/modals/orderDiscountCodes/SumOrderDiscountCodesModal";

export default {
  name: "OrderDiscountCodesList",
  data() {
    return {
      orderDiscountCodes: [],
      orderDiscountCodesCount: 0,
      filter: "",
      offset: 0,
      limit: 15,
    };
  },
  mounted() {
    EventBus.$on(
        "updateOrderDiscountCodes",
        function () {
          this.filterOrderDiscountCodes();
        }.bind(this)
    );
    this.loadOrderDiscountCodes();
    this.loadOrderDiscountCodesCount();
  },
  methods: {
    openCreateOrderDiscountCodeModal() {
      try {
        this.$modal.show(
            CreateOrderDiscountCodeModal,
            {},
            {
              height: "auto",
            },
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    openSumOrderDiscountCodesModal() {
      try {
        this.$modal.show(
            SumOrderDiscountCodesModal,
            {},
            {
              height: "auto",
            },
        );
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadOrderDiscountCodes() {
      this.$apollo.query({
        query: OrderDiscountCodes.Queries.OrderDiscountCodes,
        fetchPolicy: "no-cache",
        variables: {
          filter: this.filter,
          offset: this.offset,
          limit: this.limit,
        }
      })
          .then(({data}) => {
            if (!data || !data.orderDiscountCodes) {
              return this.$alert("Es konnten keine Wertgutscheine geladen werden.");
            }
            this.orderDiscountCodes = this.orderDiscountCodes.concat(data.orderDiscountCodes);
          });
    },
    loadOrderDiscountCodesCount() {
      this.$apollo.query({
        query: OrderDiscountCodes.Queries.OrderDiscountCodesCount,
        fetchPolicy: "no-cache",
        variables: {
          filter: this.filter,
        }
      })
          .then(({data}) => {
            if (!data || !data.orderDiscountCodesCount) {
              return;
            }
            this.orderDiscountCodesCount = data.orderDiscountCodesCount;
          });
    },
    loadMoreOrderDiscountCodes() {
      try {
        if (this.orderDiscountCodes.length == this.orderDiscountCodesCount) {
          return;
        }
        this.offset += this.limit;
        this.loadOrderDiscountCodes();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    filterOrderDiscountCodes() {
      try {
        this.orderDiscountCodes = [];
        this.offset = 0;
        this.loadOrderDiscountCodes();
        this.loadOrderDiscountCodesCount();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    createOrderDiscountCode() {
      try {
        this.$apollo.mutate({
          mutation: OrderDiscountCodes.Mutations.CreateOrderDiscountCode,
        })
        .then(({data}) => {
          if (!data || !data.createOrderDiscountCode) {
            return this.$alert("Der Wertgutschein konnte nicht erstellt werden.");
          }
          this.loadOrderDiscountCodes();
          this.loadOrderDiscountCodesCount();
        })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped>

</style>
