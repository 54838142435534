<template lang="pug">
  .row.mt-2(v-if="item")
    .col-12
      h5 {{ moment(item.AdvertisedFrom).format('DD.MM.YYYY') }} - {{ moment(item.AdvertisedTill).format('DD.MM.YYYY') }}
    .col-12.mb-2
      .liveshopping-highlight-wrap
        .row.no-gutters
          .col-12.col-sm-8.order-sm-2.p-0.m-0
            .ls-img-1
              img.w-100.h-100(:src="firstImage + 'medium.jpg'" @click="changeImage(1)" v-if="firstImage && firstImage.length")
          .col-12.col-sm-4.order-sm-1.p-0
            .row.no-gutters
              .col-6.col-sm-12
                .ls-img-2
                  img.w-100.h-100(:src="secondImage + 'medium.jpg'" @click="changeImage(2)" v-if="secondImage && secondImage.length")
              .col-6.col-sm-12
                .ls-img-3
                  img.w-100.h-100(:src="thirdImage + 'medium.jpg'" @click="changeImage(3)" v-if="thirdImage && thirdImage.length")
        .row
          .col-12
            .ls-colored-box.p-3(:style="`background-color:${hex}`")
              .h1.txt-white.text-left {{ headline }}
              .h2.txt-white.d-none.d-md-block.text-left {{ subline }}

    .col-3.mt-2(v-for="productImage of getHostImages(host)" v-if="host")
      img.img-fluid(:src="`${productImage.RemoteURL}large.jpg`" :class="{ 'overlay': selectedImage == productImage.RemoteURL }" @click="selectedImage = productImage.RemoteURL")

    .col-12.mt-2
      input.form-control.text-center(type="text" v-model="hex" disabled)
    .col-12(v-for="color in colorPalette")
      button.button.button-primary.button-td-grey.w-100(:style="{ 'background': color.Hex }" @click="hex = color.Hex") {{ color.Hex.toUpperCase() }}

    .col-6.mt-2
      input.form-control(type="text" v-model="headline" placeholder="Headline")
    .col-6.mt-2
      input.form-control(type="text" v-model="subline" placeholder="Subline")

    .col-12.mt-2
      .float-right
        button.button.button-primary.button-tdays(@click="saveItemHighlightImages")
          | speichern

</template>

<script>
import moment from "moment";
import {Items} from "@/graphql/Items.ts";
import {Utils} from "@/graphql/Utils.ts";
import {Hosts} from "@/graphql/Hosts.ts";

export default {
  name: "ItemHighlight",
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      hex: "",
      firstImage: "",
      secondImage: "",
      thirdImage: "",
      selectedImage: "",
      colorPalette: [],

      headline: "",
      subline: "",

      host: undefined,

      moment: moment,
    }
  },
  mounted() {
    try {
      this.loadHost();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    loadHost() {
      try {
        this.$apollo.query({
          query: Hosts.Queries.Host,
          variables: {
            id: this.item.HostId,
          }
        })
            .then(({data}) => {
              if (!data || !data.host) {
                return this.$alert("Es konnte kein Gastgeber geladen werden.");
              }
              this.host = data.host;
              this.fillViewData();
            })
            .catch((e) => {
              console.log(e);
              this.$alert(e.message);
            });
      } catch (e) {
        console.log(e);
        return this.$alert(e.message);
      }
    },
    fillViewData() {
      try {
        if (!this.item) {
          return this.$alert("Es wurde kein Artikel übergeben.");
        }
        if (!this.item.CustomAttributes.HighlightImages) {
          const images = this.getHostImages(this.host);
          if (images.length < 3) {
            return;
          }
          this.hex = "#000000";
          this.firstImage = images[0].RemoteURL;
          this.secondImage = images[1].RemoteURL;
          this.thirdImage = images[2].RemoteURL;
        } else {
          this.hex = this.item.CustomAttributes.HighlightImages.hex;
          this.firstImage = this.item.CustomAttributes.HighlightImages.firstImage;
          this.secondImage = this.item.CustomAttributes.HighlightImages.secondImage;
          this.thirdImage = this.item.CustomAttributes.HighlightImages.thirdImage;
        }

        this.headline = this.item.CustomAttributes.Custom_LiveShopping_Headline ? this.item.CustomAttributes.Custom_LiveShopping_Headline.trim() : "";
        this.subline = this.item.CustomAttributes.Custom_LiveShopping_Subline ? this.item.CustomAttributes.Custom_LiveShopping_Subline.trim() : "";
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    getHostImages(host) {
      try {
        if (!host) {
          return undefined;
        }
        let images = host.Images.filter((image) => {
          return !image.Name.toLowerCase().includes('logo') && !image.Name.toLowerCase().includes('ebay') && !image.Name.toLowerCase().includes('slider')
        });
        return images;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    changeImage(imageNumber) {
      try {
        if (!imageNumber) {
          return;
        }
        if (!this.selectedImage) {
          return;
        }
        switch (imageNumber) {
          case 1:
            this.firstImage = this.selectedImage;
            break;
          case 2:
            this.secondImage = this.selectedImage;
            break;
          case 3:
            this.thirdImage = this.selectedImage;
            break;
        }
        this.selectedImage = undefined;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    saveItemHighlightImages() {
      try {
        this.$apollo.mutate({
          mutation: Items.Mutations.SaveItemHighlightImages,
          variables: {
            itemId: this.item.id,
            hex: this.hex,
            firstImage: this.firstImage,
            secondImage: this.secondImage,
            thirdImage: this.thirdImage,
            headline: this.headline,
            subline: this.subline,
          }
        })
            .then(({data}) => {
              if (!data || !data.saveItemHighlightImages) {
                return this.$alert("Das Highlight konnte nicht gespeichert werden.");
              }
              if (data.saveItemHighlightImages.Message) {
                this.$alert(data.saveItemHighlightImages.Message);
              }
              this.loadHighlightItem();
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  },
  apollo: {
    colorPalette: {
      query: Utils.Queries.ColorPalette,
      variables() {
        return {
          imageSource: this.firstImage ? (this.firstImage + "large.jpg") : undefined,
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
