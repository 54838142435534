import gql from "graphql-tag";

export const SelectionVoucherItems = {
    Queries: {
        SelectionVoucherItems: gql`
        query selectionVoucherItems($selectionVoucherId: ID) {
            selectionVoucherItems(selectionVoucherId: $selectionVoucherId) {
                id
                ItemId
                SelectionVoucherId
                Item {
                    id
                    Name
                }
            }
        }`,
    },
    Mutations: {
        CreateSelectionVoucherItem: gql`
        mutation createSelectionVoucherItem($systemItemId: ID, $selectionVoucherId: ID) {
            createSelectionVoucherItem(systemItemId: $systemItemId, selectionVoucherId: $selectionVoucherId) {
                IsSuccess
                Message
            }
        }`,
        DeleteSelectionVoucherItem: gql`
        mutation deleteSelectionVoucherItem($selectionVoucherItemId: ID) {
            deleteSelectionVoucherItem(selectionVoucherItemId: $selectionVoucherItemId) {
                IsSuccess
                Message
            }
        }`,
    },
};
