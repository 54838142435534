<template lang="pug">
.order-discount-code-details
  .container-fluid
    .row(v-if="orderDiscountCode")
      .col-12
        h4 Wertgutscheindetails

      .col-4
        .form-group
          label.form-label(for="Code") Code
          input.form-control#Code(type="text" :value="orderDiscountCode.Code" disabled)
      .col-4
        .form-group
          label.form-label(for="Value") Wert
          input.form-control#Value(type="text" :value="value" disabled)
      .col-4
        .form-group
          label.form-label(for="EndOfLiveAt") Gültigkeitsdatum
          input.form-control#EndOfLiveAt(type="datetime-local" :value="orderDiscountCode.EndOfLiveAt | formatInputDateTime" @change="orderDiscountCode.EndOfLiveAt = $event.target.value;" :disabled="orderDiscountCode.RedeemedAt")

      .col-6
        label.form-label &nbsp;
        select.form-control(v-model="orderDiscountCode.DiscountCreatedByOrigin" :disabled="orderDiscountCode.RedeemedAt")
          option(:value="1") Rabattgutschein
          option(:value="2") Wertgutschein
      .col-6
        label.form-label &nbsp;
        ModelListSelect.rounded-0(
          :list="campaignTypes"
          option-value="id"
          option-text="Name"
          v-model="orderDiscountCode.CampaignTypeId"
          placeholder="Bitte wählen Sie eine Kampagne aus"
          :class="{ 'disabled': orderDiscountCode.RedeemedAt }"
        )

      .col-4.mt-2
        .form-group
          label.form-label(for="RedeemedAt") Eingelöst am
          input.form-control#RedeemedAt(type="datetime-local" :value="orderDiscountCode.RedeemedAt | formatInputDateTime" disabled)
      .col-4.mt-2
        .form-group
          label.form-label(for="RedeemedWithOrderNumber") Eingelöst mit Auftragsnummer
          input.form-control#RedeemedWithOrderNumber(type="text" :value="orderDiscountCode.RedeemedWithOrderNumber" disabled)
      .col-4.mt-2
        .form-group
          label.form-label(for="RedeemedWithOrderRowId") Eingelöst mit Auftragsartikel
          input.form-control#RedeemedWithOrderRowId(type="text" :value="orderDiscountCode.RedeemedWithOrderRowId" disabled)

      .col-6.mt-2
        label.form-label(for="IsDisabled") &nbsp;
        a.button.button-primary.w-100#IsDisabled(@click="setIsDisabled" :disabled="orderDiscountCode.RedeemedAt" :class="{ 'button-tpics': orderDiscountCode.IsDisabled, 'button-tdays': !orderDiscountCode.IsDisabled, 'disabled': orderDiscountCode.RedeemedAt }")
          | {{ orderDiscountCode.IsDisabled ? 'deaktiviert' : 'aktiviert' }}

      .col-6.mt-2
        .form-group
          label.form-label(for="DisabledAt") Deaktiviert am
          input.form-control#DisabledAt(type="datetime-local" :value="orderDiscountCode.DisabledAt | formatInputDateTime" disabled)

      .col-6.mt-2
        .form-group
          label.form-label(for="AvailableQuantity") Verfügbare Gutscheine
          input.form-control#AvailableQuantity(type="number" :value="orderDiscountCode.AvailableQuantity" disabled)
      .col-6.mt-2
        .form-group
          label.form-label(for="TotalQuantity") Gesamte Gutscheine
          input.form-control#TotalQuantity(type="number" :value="orderDiscountCode.TotalQuantity" disabled)

      .col-12.mt-3(v-if="orderDiscountCode.MultiOrderNumberRelationArrayWrapper")
        table.table.w-100
          thead
            tr
              th Eingelöst am
              th Eingelöst mit Auftragsnummer
              th Eingelöst mit Auftragsartikel
          tbody
            tr(v-for="multiOrderNumberRelationArrayWrapper in orderDiscountCode.MultiOrderNumberRelationArrayWrapper" :key="multiOrderNumberRelationArrayWrapper.RedeemedWithOrderRowId")
              td {{ multiOrderNumberRelationArrayWrapper.RedeemedAt | formatDateTime }}
              td {{ multiOrderNumberRelationArrayWrapper.RedeemedWithOrderNumber }}
              td {{ multiOrderNumberRelationArrayWrapper.RedeemedWithOrderRowId }}

      .col-12.mt-3
        .float-right
          button.button.button-primary.button-tdays(@click="updateOrderDiscountCode") speichern

</template>

<script>
import {OrderDiscountCodes} from "@/graphql/OrderDiscountCodes.ts";
import {CampaignTypes} from "@/graphql/CampaignTypes.ts";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "OrderDiscountCodeDetails",
  props: {
    orderDiscountCodeId: {
      type: String,
      required: true,
    }
  },
  components: {
    ModelListSelect,
  },
  data() {
    return {
      orderDiscountCode: undefined,
      campaignTypes: [],
    }
  },
  mounted() {
    this.loadOrderDiscountCode();
  },
  computed: {
    value() {
      try {
        if (this.orderDiscountCode.IsPercentage) {
          return this.$options.filters.formatPercentage(this.orderDiscountCode.Value);
        }
        return this.$options.filters.formatCurrency(this.orderDiscountCode.Value);
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
        return undefined;
      }
    }
  },
  methods: {
    setIsDisabled() {
      try {
        if (this.orderDiscountCode.RedeemedAt) {
          return;
        }
        this.orderDiscountCode.IsDisabled = !this.orderDiscountCode.IsDisabled;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadOrderDiscountCode() {
      try {
        this.$apollo.query({
          query: OrderDiscountCodes.Queries.OrderDiscountCodeById,
          fetchPolicy: "no-cache",
          variables: {
            id: this.orderDiscountCodeId,
          }
        })
            .then(({data}) => {
              if (!data || !data.orderDiscountCodeById) {
                return this.$alert("Es konnte kein Wertgutschein geladen werden.");
              }
              this.orderDiscountCode = data.orderDiscountCodeById;
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    updateOrderDiscountCode() {
      try {
        this.$apollo.mutate({
          mutation: OrderDiscountCodes.Mutations.UpdateOrderDiscountCode,
          variables: {
            orderDiscountCode: this.orderDiscountCode,
          }
        })
            .then(({data}) => {
              if (!data || !data.updateOrderDiscountCode) {
                return this.$alert("Der Wertgutschein konnte nicht gespeichert werden.");
              }
              if (data.updateOrderDiscountCode.Message) {
                this.$alert(data.updateOrderDiscountCode.Message);
              }
              this.loadOrderDiscountCode();
            });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
  apollo: {
    campaignTypes: {
      query: CampaignTypes.Queries.CampaignTypes,
    }
  },
}
</script>

<style scoped>

</style>
