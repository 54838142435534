<template lang="pug">
.sum-order-discount-codes-modal.p-4
  .container-fluid
    .row
      .col-12
        h5 Wertgutscheine zusammenfassen

      .col-12
        .form-group
          label.form-label Code
          .input-group
            input.form-control(type="text" v-model="code" v-on:keyup.enter="loadOrderDiscountCode" placeholder="Code")
            .input-group-append
              button.button.button-primary.button-tdays(@click="loadOrderDiscountCode") suchen

      .col-12
        table.table.w-100
          thead
            tr
              th Id
              th Code
              th Wert
              th #
          tbody
            tr(v-for='orderDiscountCode in orderDiscountCodes' :key='orderDiscountCode.id')
              td {{ orderDiscountCode.id }}
              td {{ orderDiscountCode.Code }}
              td {{ orderDiscountCode.Value | formatCurrency }}
              td
                .float-right
                  button.button.button-primary.button-tpics(@click="deleteOrderDiscountCode(orderDiscountCode)")
                    | löschen

      .offset-8.col-4
        .float-right
          label Summe: {{ orderDiscountCodesSumValue | formatCurrency }}

      .col-12
        .form-group
          label.form-label(for="EndOfLiveAt") Gültigkeitsdatum
          input.form-control#EndOfLiveAt(type="datetime-local" :value="endOfLiveAt | formatInputDateTime" @change="endOfLiveAt = $event.target.value;")


      .col-6.mt-2
        button.button.button-primary.button-tdays.w-100(type='button' @click="sumOrderDiscountCodes")
          | speichern
      .col-6.mt-2
        button.button.button-primary.button-tpics.w-100(type='button' @click="$emit('close')")
          | schließen

</template>

<script>
import {OrderDiscountCodes} from "@/graphql/OrderDiscountCodes.ts";
import EventBus from "@/views/event-bus";
import moment from "moment";

export default {
  name: "SumOrderDiscountCodesModal",
  data() {
    return {
      code: "",
      endOfLiveAt: moment().add(3, "years").endOf("year").toDate(),
      orderDiscountCodes: [],
    }
  },
  computed: {
    orderDiscountCodesSumValue() {
      try {
        let sumValue = 0.00;
        for (const orderDiscountCode of this.orderDiscountCodes) {
          sumValue += orderDiscountCode.Value;
        }
        return sumValue;
      } catch (e) {
        console.log(e);
        return 0.00;
      }
    }
  },
  methods: {
    loadOrderDiscountCode() {
      try {
        if (!this.code) {
          return this.$alert("Bitte geben Sie zuerst einen Code ein.");
        }
        if (this.code.length != 6) {
          return this.$alert("Der Code muss genau 6 Zeichen lang sein.");
        }
        this.$apollo.query({
          query: OrderDiscountCodes.Queries.OrderDiscountCode,
          variables: {
            code: this.code,
          }
        })
        .then(({ data }) => {
          if (!data || !data.orderDiscountCode) {
            return this.$alert(`Es konnte kein gültiger Wertgutschein mit dem Code ${this.code} gefunden werden.`);
          }
          const orderDiscountCode = data.orderDiscountCode;
          if (!orderDiscountCode) {
            return this.$alert("Der Wertgutschein konnte nicht geladen werden.");
          }
          if (orderDiscountCode.IsPercentage) {
            return this.$alert("Der Wertgutschein ist prozentual und kann somit nicht zusammengefasst werden.");
          }
          this.orderDiscountCodes.push(data.orderDiscountCode);
          this.code = "";
        })
        .catch((e) => {
          console.log(e);
          this.$alert(e.message);
        });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    deleteOrderDiscountCode(orderDiscountCode) {
      try {
        if (!orderDiscountCode) {
          return this.$alert("Es wurde kein Wertgutschein übergeben.");
        }
        const orderDiscountCodeIndex = this.orderDiscountCodes.indexOf(orderDiscountCode);
        if (orderDiscountCodeIndex == undefined) {
          return this.$alert("Es konnte kein Wertgutschein-Index ermittelt werden.");
        }
        this.orderDiscountCodes.splice(orderDiscountCodeIndex, 1);
      } catch (e) {
        console.log(e);
        this.$alert(e);
      }
    },
    sumOrderDiscountCodes() {
      try {
        if (this.orderDiscountCodes.length == 0) {
          return this.$alert("Es sind keine Wertgutscheine für die Zusammenfassung vorhanden.")
        }
        const codes = [];
        this.orderDiscountCodes.forEach(c => codes.push(c.Code));
        this.$apollo.mutate({
          mutation: OrderDiscountCodes.Mutations.SumOrderDiscountCodes,
          variables: {
            codes: codes,
            endOfLiveAt: this.endOfLiveAt,
          }
        })
        .then(({ data }) => {
          if (!data || !data.sumOrderDiscountCodes) {
            return this.$alert("Die Wertgutscheine konnten nicht zusammengefasst werden.");
          }
          if (data.sumOrderDiscountCodes.Message) {
            data.sumOrderDiscountCodes.IsSuccess ? this.$swal(data.sumOrderDiscountCodes.Message) : this.$alert(data.sumOrderDiscountCodes.Message);
          }
          if (data.sumOrderDiscountCodes.IsSuccess) {
            EventBus.$emit("updateOrderDiscountCodes");
          }
          if (data.sumOrderDiscountCodes.StringData) {
            this.$router.push({ name: "OrderDiscountCodeDetails", params: { orderDiscountCodeId: data.sumOrderDiscountCodes.StringData } });
            this.$emit("close");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$alert(e.message);
        });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  }
};
</script>

<style scoped>

</style>
