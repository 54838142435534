<template lang="pug">
  #charts
    .row
      .col-12.mt-3
        h3 Anzahl Teilnehmer {{this.countAllParticipants}}
        p
          | ...davon neue Teilnehmer =>&nbsp;
          b {{ this.newParticipantsCount }}
          | &nbsp;(Erste Gewinnspielanmeldung im System)
        p(v-if="getContestName.length > 1")
          | ...davon neue Newsletter-Abonnenten =>&nbsp;
          b {{this.newNewsletterReceiverCount}}
        p
          | ...davon geworbene Teilnehmer:&nbsp;
          b {{this.countReferrers}}
        hr
      .col-6.p-3
        .card
          .card-header
            h5.card-title Neue Teilnehmer pro Tag
          .card-body
            Bar(
              chart-id="countAllParticipantsForDay"
              ref="countAllParticipantsForDay"
              :chart-data="this.countAllParticipantsForDay"
              :chart-options="this.chartOptions"
            )
      .col-6.p-3
        .card
          .card-header
            h5.card-title Herkunft
          .card-body
            ul.list-group(v-if="countSignUpThroughSource")
              li.list-group-item.d-flex.justify-content-between.align-items-center(v-for="index in countSignUpThroughSource.labels.length")
                | {{countSignUpThroughSource.labels[index-1]}}
                b {{countSignUpThroughSource.datasets[0].data[index-1]}}
            hr
            Pie(
              chart-id="countSignUpThroughSource"
              ref="countSignUpThroughSource"
              :chart-data="this.countSignUpThroughSource"
              :chart-options="this.chartOptions"
            )
      .col-12.p-3(v-if="newOrdersByContestParticipants.length > 0")
        .card
          .card-header
            h5.card-title Sales
          .card-body
            .row
              .col-sm-12.col-lg-8
                p Die Statistik bezieht sich nur auf Käufe ab dem möglichen Registrierungsdatum des Gewinnspiel + 1 Jahr und der Kunde muss "neuer" Newsletter-Abonnent sein.
                  small &nbsp;(Zum Zeitpunkt der Registrierung am Gewinnspiel)
                span.d-inline.text-center
                  strong Anzahl Sales: {{newOrdersByContestParticipants.length}}
                  br
                  strong Summe: {{sumParticipantSales | formatCurrency}}
                  br

                table.table.table-responsive
                  thead
                    tr
                      th Umsatz
                      th Bestellnummer
                      th Bestelldatum
                      th Kunde
                      th Herkunft
                      th Status
                      th Versand
                  tbody
                    tr(v-for="row in newOrdersByContestParticipants")
                      td {{row.TotalPriceGross | formatCurrency}}
                      td {{row.OrderNumber}}
                      td {{row.createdAt | formatDate}}
                      td {{row.Email}}
                      td {{row.Ref}}
                      td {{row.Status}}
                      td {{row.Shipping}}
              .col-sm-12.col-lg-4
                Pie(
                  chart-id="getSalesData_GroupByRef"
                  ref="getSalesData_GroupByRef"
                  :chart-data="this.getSalesData_GroupByRef"
                  :chart-options="this.chartOptions"
                )
                Pie(
                  chart-id="getSalesData_groupByStatus"
                  ref="getSalesData_groupByStatus"
                  :chart-data="this.getSalesData_groupByStatus"
                  :chart-options="this.chartOptions"
                )
                Pie(
                  chart-id="getSalesData_GroupByShipping"
                  ref="getSalesData_GroupByShipping"
                  :chart-data="this.getSalesData_GroupByShipping"
                  :chart-options="this.chartOptions"
                )

</template>

<script>
import EventBus from "@/views/event-bus";

const _ = require('lodash');
import {MarketingPromoContestParticipants} from "@/graphql/MarketingPromoContestParticipants.ts";
import {Bar, Pie, PolarArea} from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  RadialLinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, RadialLinearScale)

export default {
  name: "MarketingPromoContestCharts",
  props: {
    MarketingPromoContestId: {
      type: String,
      required: true,
    },
    MarketingPromoContestName: {
      type: String,
      required: false
    }
  },
  components: {Bar, Pie, PolarArea},
  data() {
    return {
      participants: [],
      newParticipantsCount: "...",
      newNewsletterReceiverCount: 0,
      newOrdersByContestParticipants: [],
      chartOptions: {
        responsive: true
      },
      timer: ''
    }
  },
  computed: {
    getContestName() {
      if (this.MarketingPromoContestName) {
        return this.MarketingPromoContestName.toLowerCase();
      } else {
        return "";
      }
    },
    countAllParticipants() {
      return this.participants.length;
    },
    countAllParticipantsForDay() {
      let groupedByDay = _.countBy(this.participants, (p) => {
        return this.$moment(p.createdAt).format("DD.MM.YYYY")
      });
      return {
        labels: _.keys(groupedByDay),
        datasets: [
          {
            label: 'Anzahl Teilnehmer',
            backgroundColor: this.generateRandomHexColor(),
            data: groupedByDay
          }
        ]
      };
    },
    sumParticipantSales() {
      return _.sumBy(this.newOrdersByContestParticipants, 'TotalPriceGross');
    },
    getSalesData_groupByStatus() {
      let groupedByStatus = _.countBy(this.newOrdersByContestParticipants, (o) => {
        return o.Status;
      });

      return {
        labels: _.keys(groupedByStatus),
        datasets: [
          {
            label: 'Anzahl Sales',
            backgroundColor: [...Array(Object.values(groupedByStatus).length)].map(() => this.generateRandomHexColor()),
            data: Object.values(groupedByStatus)
          }
        ]
      };
    },
    getSalesData_GroupByRef() {
      let groupedByRef = _.countBy(this.newOrdersByContestParticipants, (o) => {
        return o.Ref;
      });

      return {
        labels: _.keys(groupedByRef),
        datasets: [
          {
            label: 'Anzahl Sales',
            backgroundColor: [...Array(Object.values(groupedByRef).length)].map(() => this.generateRandomHexColor()),
            data: Object.values(groupedByRef)
          }
        ]
      };
    },
    getSalesData_GroupByShipping() {
      let groupedByShip = _.countBy(this.newOrdersByContestParticipants, (o) => {
        return o.Shipping;
      });

      return {
        labels: _.keys(groupedByShip),
        datasets: [
          {
            label: 'Anzahl Sales',
            backgroundColor: [...Array(Object.values(groupedByShip).length)].map(() => this.generateRandomHexColor()),
            data: Object.values(groupedByShip)
          }
        ]
      };
    },
    countSignUpThroughSource() {
      if (this.participants.length > 0) {
        let groupedBySource = _.countBy(this.participants, (p) => {
          return p.SignUpThroughSource
        });

        groupedBySource["no-ref"] = groupedBySource["null"];
        delete groupedBySource["null"];

        groupedBySource = Object.entries(groupedBySource);
        groupedBySource = Object.fromEntries(_.sortBy(groupedBySource, (o) => {
          return o[1];
        }));

        return {
          labels: _.keys(groupedBySource),
          datasets: [
            {
              label: 'Anzahl Teilnehmer',
              backgroundColor: [...Array(Object.values(groupedBySource).length)].map(() => this.generateRandomHexColor()),
              data: Object.values(groupedBySource)
            }
          ]
        };
      } else {
        return {
          labels: [],
          datasets: []
        };
      }

    },
    countReferrers() {
      const groupedByRef = _.filter(this.participants, (p) => {
        return p.MarketingPromoParticipantReferrerId !== null
      });
      return Object.values(groupedByRef).length;
    }
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
  mounted() {
    try {
      EventBus.$on(
          "updateMarketingPromoContestCharts",
          function () {
            this.loadData();
          }.bind(this)
      );
      this.loadData();
      this.timer = setInterval(this.loadData, 300000);
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    generateRandomHexColor() {
      const randomColor =
          "#" + Math.floor(Math.random() * 16777215).toString(16);
      if (randomColor.length !== 7) {
        return this.generateRandomHexColor();
      } else {
        return randomColor;
      }
    },
    async loadData() {
      this.loading = true;
      EventBus.$emit("changeLoadingState", true);
      await this.loadParticipants();
      await this.loadNewParticipantsCount();
      await this.loadNewNewsletterReceiversCount();
      await this.loadOrdersByContestParticipants();
      EventBus.$emit("changeLoadingState", false);
      this.loading = false;
    },
    async loadParticipants() {
      try {
        this.$apollo.query({
          query: MarketingPromoContestParticipants.Queries.ParticipantsByContest,
          variables: {
            marketingPromoContestId: this.MarketingPromoContestId,
          },
          fetchPolicy: "network-only",
        })
            .then(({data}) => {
              if (!data || !data.participantsByContest) {
                return this.$alert("Es konnten keine Teilnehmer geladen werden.");
              }
              this.participants = _.orderBy(data.participantsByContest, ['createdAt'], ['asc']);
            })
            .catch((e) => {
              console.log(e);
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadNewParticipantsCount() {
      try {
        this.$apollo.query({
          query: MarketingPromoContestParticipants.Queries.NewParticipantsByContestCount,
          variables: {
            marketingPromoContestId: this.MarketingPromoContestId,
          },
          fetchPolicy: "network-only",
        })
            .then(({data}) => {

              if (!data || data.newParticipantsByContestCount == undefined) {
                return this.$alert("Es konnten keine neuen Teilnehmer geladen werden.");
              }
              this.newParticipantsCount = data.newParticipantsByContestCount;
            })
            .catch((e) => {

              console.log(e);
              this.$alert(e.message);
            })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadNewNewsletterReceiversCount() {
      try {

        if (this.MarketingPromoContestName) {
          this.$apollo.query({
            query: MarketingPromoContestParticipants.Queries.NewNewsletterReceiversByContest,
            variables: {
              marketingPromoContestName: this.getContestName,
            },
            fetchPolicy: "network-only",
          })
              .then(({data}) => {

                if (!data || data.newNewsletterReceiverByContest === undefined) {
                  return this.$alert("Es konnten keine neuen NL-Abonnenten geladen werden.");
                }
                this.newNewsletterReceiverCount = data.newNewsletterReceiverByContest;
              })
              .catch((e) => {

                console.log(e);
                this.$alert(e.message);
              })
        }
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadOrdersByContestParticipants() {
      try {

        if (this.MarketingPromoContestId) {
          this.$apollo.query({
            query: MarketingPromoContestParticipants.Queries.NewOrdersByContestParticipants,
            variables: {
              marketingPromoContestId: this.MarketingPromoContestId,
              marketingPromoContestName: this.getContestName
            },
            fetchPolicy: "network-only",
          })
              .then(({data}) => {

                if (!data || data.newOrdersByContestParticipants === undefined) {
                  return this.$alert("Es konnten keine Teilnehmer-Order Statistiken geladen werden");
                }
                this.newOrdersByContestParticipants = data.newOrdersByContestParticipants;
              })
              .catch((e) => {

                console.log(e);
                this.$alert(e.message);
              })
        }
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    calculatePercentage(value) {
      try {
        if (!value) {
          return "0,00%"
        }
        let percent = (value * 100) / this.countAllParticipants;
        return `${this.$math.round(percent, 2).toString().replace(".", ",")} %`;
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
