import gql from "graphql-tag";

export const BackendUsers = {
    Mutations: {
        LoginBackendUser: gql`
        mutation loginBackendUser($username: String, $password: String) {
            loginBackendUser(username: $username, password: $password) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
