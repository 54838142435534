import gql from "graphql-tag";

export const Utils = {
    Queries: {
        ColorPalette: gql`
            query colorPalette($imageSource: String) {
                colorPalette(imageSource: $imageSource) {
                    Hex
                    RGB
                }
            }`,
    }
};
