<template lang="pug">
.login
  .container-fluid
    .row.mt-5
      .col-12.text-center
        h2 Login
      .offset-3.col-6.mt-2
        .form-group
          label.form-label E-Mail Adresse
          input.form-control.custom-control(v-model="username" ref="email" placeholder="E-Mail Adresse")
      .offset-3.col-6.mt-2
        .form-group
          label.form-label Passwort
          input.form-control.custom-control(v-model="password" placeholder="Passwort" type="password" v-on:keyup.enter="login")

        .form-group.mt-2
          button.button.button-primary.button-tdays.w-100(@click="login")
            | Login

    footer.mt-auto.py-3
      .container
        .text-muted.text-center
          | &copy; {{year}} touriDat GmbH & Co. KG
          br
          | Version: 1.0.0

</template>

<script>
import {BackendUsers} from "@/graphql/BackendUsers.ts";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  setup(){
    const date = new Date();
    const year = date.getFullYear();

    return { year }
  },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      try {
        this.$apollo.mutate({
          mutation: BackendUsers.Mutations.LoginBackendUser,
          variables: {
            username: this.username,
            password: this.password,
          }
        })
        .then(({ data }) => {
          if (!data || !data.loginBackendUser) {
            return this.$alert("Der Benutzer konnte nicht eingeloggt werden, versuchen Sie es später erneut.");
          }
          if (data.loginBackendUser.Message) {
            this.$alert(data.loginBackendUser.Message);
          }
          if (data.loginBackendUser.IsSuccess && data.loginBackendUser.StringData) {
            this.$session.start();
            this.$session.set("token",data.loginBackendUser.StringData);
            this.$router.push({ name: "Home" });
          }
        })
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    }
  }
}
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
}
</style>
