import gql from "graphql-tag";

export const OrderVoucherCodes = {
    Queries: {
        OrderVoucherCodes: gql`
        query orderVoucherCodes($filter: String, $offset: Int, $limit: Int) {
            orderVoucherCodes(filter: $filter, offset: $offset, limit: $limit) {
                id
                BoughtWithOrderNumber
                BoughtWithOrderRowId
                RedeemedAt,
                RedeemedWithOrderNumber,
                RedeemedWithOrderRowId,
                Code,
                InternalNote,
                IsDisabled,
                DisabledAt,
                EndOfLiveAt,
                Url,
                createdAt,
                updatedAt,
                deletedAt,
                ShopCompanyId,
                SelectionVoucherId,
                SelectionVoucher {
                    id
                    Name
                    Headline
                    Subline
                    RelativeFilePath
                    Description
                    VoucherImage
                }
            }
        }`,
        OrderVoucherCodesCount: gql`
        query orderVoucherCodesCount($filter: String) {
            orderVoucherCodesCount(filter: $filter)
        }`,
        OrderVoucherCodeById: gql`
        query orderVoucherCodeById($id: ID) {
            orderVoucherCodeById(id: $id) {
                id
                BoughtWithOrderNumber
                BoughtWithOrderRowId
                RedeemedAt
                RedeemedWithOrderNumber
                RedeemedWithOrderRowId
                Code
                InternalNote
                IsDisabled
                DisabledAt
                EndOfLiveAt
                Url
                createdAt
                updatedAt
                deletedAt
                ShopCompanyId
                SelectionVoucherId
            }
        }`,
    },
    Mutations: {
        CreateOrderVoucherCode: gql`
        mutation createOrderVoucherCode($selectionVoucherId: ID) {
            createOrderVoucherCode(selectionVoucherId: $selectionVoucherId) {
                IsSuccess
                Message
            }
        }`,
        UpdateOrderVoucherCode: gql`
        mutation updateOrderVoucherCode($orderVoucherCode: OrderVoucherCodesInput) {
            updateOrderVoucherCode(orderVoucherCode: $orderVoucherCode) {
                IsSuccess
                Message
            }
        }`,
    },
};
