import gql from "graphql-tag";

export const Hosts = {
    Queries: {
        Hosts: gql`
        query hosts($filter: String, $offset: Int, $limit: Int) {
            hosts(filter: $filter, offset: $offset, limit: $limit) {
                id
                SystemHostId
                Name
            }
        }`,
        HostsCount: gql`
        query hostsCount($filter: String) {
            hostsCount(filter: $filter)
        }`,
        Host: gql`
        query host($id: ID) {
            host(id: $id) {
                id
                SystemHostId
                Name
                MetaDescription
                MetaKeyword
                MetaTitle
                Latitude
                Longitude
                Url
                Stars
                CityRegion
                Images
                CustomAttributes {
                    Title
                    Subtitle
                    HotelDescription
                    EnvironmentDescription
                    SeoDescription
                    SeoGeneralDescription
                    SeoEquipmentDescription
                    SeoEnvironmentDescription
                }
            }
        }`,
    }
}
