import gql from "graphql-tag";

export const MarketingPromoContestParticipants = {
    Queries: {
        ParticipantsByContest: gql`
        query participantsByContest($marketingPromoContestId: ID) {
            participantsByContest(marketingPromoContestId: $marketingPromoContestId) {
                id
                createdAt
                updatedAt
                deletedAt
                MarketingPromoContestId
                MarketingPromoParticipantId
                MarketingPromoParticipantReferrerId
                Ticket
                SignUpThroughSource
                RawRequest
            }
        }`,
        NewParticipantsByContestCount: gql`
        query newParticipantsByContestCount($marketingPromoContestId: ID) {
            newParticipantsByContestCount(marketingPromoContestId: $marketingPromoContestId)
        }`,
        NewNewsletterReceiversByContest: gql`
        query Query($marketingPromoContestName: String) {
            newNewsletterReceiverByContest(marketingPromoContestName: $marketingPromoContestName)
        }`,
        NewOrdersByContestParticipants: gql`
        query NewOrdersByContestParticipants($marketingPromoContestId: ID, $marketingPromoContestName: String) {
            newOrdersByContestParticipants(marketingPromoContestId: $marketingPromoContestId, marketingPromoContestName: $marketingPromoContestName){
                TotalPriceGross
                OrderNumber
                createdAt
                Email       
                Ref
                Status
                Shipping
            }
        }`,
    },
    Mutations: {
        SendMarketingPromoContestParticipantSignupEmail: gql`
        mutation sendMarketingPromoContestParticipantSignupEmail($email: String) {
            sendMarketingPromoContestParticipantSignupEmail(email: $email) {
                IsSuccess
                Message
            }
        }`,
        SendMarketingPromoContestFriendSharingEmail: gql`
        mutation sendMarketingPromoContestFriendSharingEmail($email: String) {
            sendMarketingPromoContestFriendSharingEmail(email: $email) {
                IsSuccess
                Message
            }
        }`,
    }
};
